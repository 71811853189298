import styled from '@emotion/styled';
import { Alert as $Alert, Button as $Button, Popover as $Popover, Switch as $Switch, Tooltip as $Tooltip } from 'antd5';

import theme from '../../../theme';

/* ============ UI ============ */

/* ------------ BUTTON ------------ */
export const Button = styled($Button)`
  &.ant-btn {
    height: auto;
    font-size: ${theme.componentFontSize};
    line-height: 1.5;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 4px;
    color: ${theme.colorsPrimaryBlue};
    border-color: ${theme.colorsPrimaryBlue};

    &:hover,
    &:focus {
      &:not(:disabled) {
        color: ${theme.colorsPrimaryBlue};
        border-color: ${theme.colorsPrimaryBlue};
      }
    }

    > .anticon + span,
    > span + .anticon,
    > .MuiSvgIcon-root + span,
    > span + .MuiSvgIcon-root {
      margin-left: 20px;
    }

    > .MuiSvgIcon-root {
      vertical-align: bottom;
    }

    &.ant-btn-primary-blue {
      &,
      &.popup-btn {
        color: ${theme.colorsWhite};
        background-color: ${theme.colorsPrimaryBlue};
        border-color: ${theme.colorsPrimaryBlue};

        &:hover,
        &:focus {
          color: ${theme.colorsWhite};
          background-color: ${theme.colorsPrimaryBlue};
          border-color: ${theme.colorsPrimaryBlue};
        }

        &[disabled],
        &.disabled {
          background-color: ${theme.colorsDisabledGreyButtons};
          border-color: ${theme.colorsDisabledGreyButtons};
          color: ${theme.colorsWhite};
          &:hover,
          &:focus {
            background-color: ${theme.colorsDisabledGreyButtons};
            border-color: ${theme.colorsDisabledGreyButtons};
            color: ${theme.colorsWhite};
          }
        }
      }

      &.disabled {
        background-color: ${theme.colorsDisabledGreyButtons};
        border-color: ${theme.colorsDisabledGreyButtons};
        color: ${theme.colorsWhite};

        &:hover,
        &:focus {
          background-color: ${theme.colorsDisabledGreyButtons};
          border-color: ${theme.colorsDisabledGreyButtons};
          color: ${theme.colorsWhite};
        }
      }
    }

    &.ant-btn-secondary-bright-blue {
      color: ${theme.colorsWhite};
      background-color: #0f7ed3;
      border-color: #0f7ed3;

      &:hover,
      &:focus {
        color: ${theme.colorsWhite};
        background-color: #0f7ed3;
        border-color: #0f7ed3;
      }
    }

    &.ant-btn-secondary-light-blue {
      color: ${theme.colorsWhite};
      background-color: #7eb5e0;
      border-color: #7eb5e0;

      &:hover,
      &:focus {
        color: ${theme.colorsWhite};
        background-color: #7eb5e0;
        border-color: #7eb5e0;
      }
    }

    &.ant-btn-primary {
      color: ${theme.colorsPrimaryBlue};
      border-color: ${theme.colorsPrimaryBlue};
      background-color: ${theme.colorsWhite};
      border-width: ${theme.componentBorderWidth};

      &:hover,
      &:focus {
        color: ${theme.colorsPrimaryBlue};
        border-color: ${theme.colorsPrimaryBlue};
        background-color: ${theme.colorsWhite};
        border-width: ${theme.componentBorderWidth};
      }

      &[disabled] {
        background-color: ${theme.colorsWhite};
        border-color: ${theme.colorsDisabledGreyButtons};
        color: ${theme.colorsDisabledGreyText};
        &:hover,
        &:focus {
          background-color: ${theme.colorsWhite};
          border-color: ${theme.colorsDisabledGreyButtons};
          color: ${theme.colorsDisabledGreyText};
        }
      }
    }

    &.ant-btn-secondary {
      color: ${theme.colorsPrimaryBlue};
      border: none;
      box-shadow: none;

      > span {
        text-decoration: underline;
      }

      &:hover,
      &:focus {
        color: ${theme.colorsPrimaryBlue};
        border: none;
        box-shadow: none;
        > span {
          text-decoration: underline;
        }
      }

      &[disabled] {
        background-color: transparent;
        border: none;
        color: ${theme.colorsDisabledGreyText};
        &:hover,
        &:focus {
          background-color: transparent;
          border: none;
          color: ${theme.colorsDisabledGreyText};
        }
      }
    }

    &.ant-btn-link {
      color: ${theme.colorsSecondaryBlue};
      font-weight: normal;
      border: none;
      box-shadow: none;
      padding: 0;

      &:hover {
        color: ${theme.colorsSecondaryBlue};
        font-weight: bold;
        border: none;
        box-shadow: none;
      }

      &:focus {
        color: ${theme.colorsSecondaryBlue};
      }

      &[disabled] {
        background-color: transparent;
        border: none;
        color: ${theme.colorsDisabledGreyText};
        &:hover,
        &:focus {
          background-color: transparent;
          font-weight: normal;
          border: none;
          color: ${theme.colorsDisabledGreyText};
        }
      }

      & > span {
        display: inline;
        text-decoration: underline;
      }
    }

    &a.ant-btn {
      line-height: 1.499;
      padding-top: 10px !important;
    }
  }
`;

/* ------------ TOOLTIP ------------ */

export const Tooltip = styled($Tooltip)`
  .ant-tooltip {
    .ant-tooltip-inner {
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.13);
      border: 1.5px solid #0f7ed3;
      background-color: ${theme.colorsWhite};
      padding: 13px;
      font-size: 14px;
      color: ${theme.colorsBlack};
      white-space: pre-wrap;
    }

    .ant-tooltip-arrow {
      display: none;
    }
  }
`;

/* ------------ SWITCH ------------ */
export const Switch = styled($Switch)`
  &.ant-switch.ant-switch-checked {
    &,
    &:hover {
      background-color: ${theme.colorsPrimaryBlue};
    }
  }
`;

// Temporary Modal Wrapper for button appearances -- replace after working on PME-1137
export const TempModalFooterWrapper = styled.div`
  * {
    ${Button?.__emotion_styles?.join('')};
  }
`;

/* ---------- POPOVER ---------- */
export const Popover = styled($Popover)`
  &.ant-popover-inner {
    border: none;
    max-width: 214px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    background-color: ${theme.colorsWhite};
    font-size: 14px;
    padding: 12px 16px;
    word-wrap: break-word;
  }

  &.ant-custom-popover {
    &.ant-popover-arrow {
      visibility: hidden;
    }
    &.ant-popover-inner-content {
      padding: 0px;
    }
    &.ant-popover-inner {
      padding: 0px;
    }
  }
`;

/* ---------- ALERT ---------- */
export const Alert = styled($Alert)`
  &.ant-alert-message {
    color: ${theme.colorsBlack};
    font-size: 14px;
    line-height: 1.5;
    vertical-align: middle;
  }
  &.ant-alert {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &.ant-alert-icon {
    top: 8px;
    left: 8px;
  }
  &.ant-alert-close-icon {
    top: 10px;
    &.anticon-close {
      color: ${theme.colorsBlack};
    }
  }
`;

import React from 'react';
import { SwitchProps as $SwitchProps } from 'antd5/lib/switch';

import { Switch as $Switch } from '../components/common/antd/antd-override';

export type SwitchProps = $SwitchProps & {
  children?: React.ReactNode;
};

export default function Switch({ ...props }: SwitchProps) {
  return <$Switch {...props}></$Switch>;
}

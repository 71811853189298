export enum MessageActionTypes {
  OPEN_CHAT = 'OPEN_CHAT',
  CLEAR = 'CLEAR',
  SET_IS_LOADING_CHAT = 'SET_IS_LOADING_CHAT',
}

export interface OpenChat {
  type: typeof MessageActionTypes.OPEN_CHAT;
  payload: string;
}

export interface Clear {
  type: typeof MessageActionTypes.CLEAR;
}

export interface setIsLoadingChat {
  type: typeof MessageActionTypes.SET_IS_LOADING_CHAT;
  payload: boolean;
}

export function openChat(id: string) {
  return {
    type: MessageActionTypes.OPEN_CHAT,
    payload: id,
  };
}

export function clear() {
  return {
    type: MessageActionTypes.CLEAR,
  };
}

export function setIsLoadingChat(isLoading: boolean) {
  return {
    type: MessageActionTypes.SET_IS_LOADING_CHAT,
    payload: isLoading,
  };
}

export type MessageAction = OpenChat | Clear | setIsLoadingChat;

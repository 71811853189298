const GRID_COL_DESKTOP_WIDTH = 64;
const GRID_GUTTER_DESKTOP_WIDTH = 32;
const MAX_PAGE_CONTENT_WIDTH = 1120;
const BREAK_POINTS = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: MAX_PAGE_CONTENT_WIDTH,
  xxl: 1600,
};
// colors and names are copied from style guide
const styles = {
  'grid-col-desktop-width': `${GRID_COL_DESKTOP_WIDTH}px`,
  'grid-gutter-desktop-width': `${GRID_GUTTER_DESKTOP_WIDTH}px`,
  'primary-color': '#074078',
  'tabs-highlight-color': '#074078',
  'font-family': '"PT Sans", sans-serif',
  'colors-primary-blue': '#074078',
  'colors-secondary-blue': '#0e75c4',
  'colors-light-blue': '#0E75C4',
  'colors-black': '#000000',
  'colors-white': '#ffffff',
  'colors-disabled-grey-text': '#6e6e6e',
  'colors-disabled-grey-buttons': '#949494',
  'colors-blue-grey': '#828fa6',
  'colors-medium-grey': '#dfe4ea',
  'colors-light-grey': '#eff2f6',
  'colors-lightest-grey': '#f6f8fa',
  'colors-error-state-100': '#e90c22',
  'colors-error-state-fill': '#fef3f4',
  'colors-success-state': '#54a654',
  'colors-success-state-fill': '#e5f5e2',
  'colors-dark-grey-outline': '#868b98',
  'colors-medium-grey-outline': '#8e949f',
  'colors-light-grey-outline': '#949494',
  'colors-dark-grey-shadow': '#bec2c7',
  'colors-visited-links': '#884488',
  'colors-hr': '#DDDDDD',
  'component-height': '44px;',
  'component-border-width': '2px',
  'component-font-size': '16px',
};

// Refer here for antD variables: https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
const antDOverride = {
  'screen-xs': `${BREAK_POINTS.xs}px`,
  'screen-sm': `${BREAK_POINTS.sm}px`,
  'screen-md': `${BREAK_POINTS.md}px`,
  'screen-lg': `${BREAK_POINTS.lg}px`,
  'screen-xl': `${BREAK_POINTS.xl}px`,
  'screen-xxl': `${BREAK_POINTS.xxl}px`,
  'screen-xs-max': `${BREAK_POINTS.sm - 1}px`,
  'screen-sm-max': `${BREAK_POINTS.md - 1}px`,
  'screen-md-max': `${BREAK_POINTS.lg - 1}px`,
  'screen-lg-max': `${BREAK_POINTS.xl - 1}px`,
  'screen-xl-max': `${BREAK_POINTS.xxl - 1}px`,
  'error-color': styles['colors-error-state-100'],
  'form-error-input-bg': styles['colors-error-state-fill'],
  'alert-error-border-color': styles['colors-error-state-100'],
  'alert-error-bg-color': styles['colors-error-state-fill'],
  'alert-error-icon-color': styles['colors-error-state-100'],
  'alert-success-border-color': styles['colors-success-state'],
  'alert-success-bg-color': styles['colors-success-state-fill'],
  'alert-success-icon-color': styles['colors-success-state'],
};

module.exports = { ...styles, ...antDOverride };

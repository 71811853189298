import { produce } from 'immer';

import { ITenantConfig } from './../../types/models/tenant-management';

import { TenantConfigAction, TenantConfigActionTypes } from '../actions/tenant';
import { CustomError } from '../../types/models/error';

export interface TenantState {
  readonly tenantConfigLoading: boolean;
  readonly tenantConfig?: ITenantConfig;
  readonly error: CustomError | null;
}

export const TENANT_CONFIG_LOCAL_STORAGE_KEY = 'tenantConfig';

function getTenantConfigFromLocalStorage() {
  let tenantConfig;
  if (typeof window !== 'undefined') {
    try {
      tenantConfig = localStorage.getItem(TENANT_CONFIG_LOCAL_STORAGE_KEY);
      if (tenantConfig) {
        tenantConfig = JSON.parse(tenantConfig);
      }
    } catch (err) {
      console.error(err);
    }
  }
  return tenantConfig;
}

export function getInitialState(): TenantState {
  const tenantConfigData = getTenantConfigFromLocalStorage() || {};

  return {
    tenantConfig: tenantConfigData?.tenantConfig,
    tenantConfigLoading: tenantConfigData?.tenantConfigLoading,
    error: tenantConfigData?.error,
  };
}

const initialState: TenantState = getInitialState();

export default function tenant(state = initialState, action: TenantConfigAction) {
  return produce(state, (draft) => {
    switch (action.type) {
      case TenantConfigActionTypes.FETCH_TENANT_CONFIG: {
        const tenantConfig = action.payload;
        draft.tenantConfig = tenantConfig;
        draft.error = null;
        draft.tenantConfigLoading = false;
        // store in localStorage
        localStorage.setItem(TENANT_CONFIG_LOCAL_STORAGE_KEY, JSON.stringify({ tenantConfig }));
        break;
      }
      case TenantConfigActionTypes.FETCH_TENANT_CONFIG_FAILURE: {
        draft.tenantConfigLoading = false;
        draft.error = action.error;
        break;
      }
      case TenantConfigActionTypes.DISMISS_TENANT_CONFIG: {
        draft.tenantConfigLoading = false;
        draft.error = null;
        localStorage.removeItem(TENANT_CONFIG_LOCAL_STORAGE_KEY);
        break;
      }
    }
  });
}

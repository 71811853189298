import { MentorBioAction, MentorBioTypes, MentorBioState } from '../../../../../types/models/mentorship';

const initialState: MentorBioState = {
  mentor: null,
};

export default function mentorBioState(state = initialState, action: MentorBioAction): MentorBioState {
  switch (action.type) {
    case MentorBioTypes.setMentorData: {
      return {
        ...state,
        mentor: action.payload,
      };
    }
    default:
      return state;
  }
}

import { combineReducers } from 'redux';

import application from './application';
import auth from './auth';
import lookup from './lookup';
import manualMentorsMatchFilterState from './myhsf/mentorship/manual-application/mentors-list/mentors-filter';
import mentorBioState from './myhsf/mentorship/mentor-bio/mentor-bio';
import message from './message';
import privileges from './privileges';
import profile from './profile';
import tenant from './tenant';

const rootReducer = combineReducers({
  application,
  auth,
  lookup,
  privileges,
  manualMentorsMatchFilterState,
  mentorBioState,
  message,
  tenant,
  profile,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

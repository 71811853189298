import { AppThunk } from '.';

import { CustomError } from '../../types/models/error';
import { Permission, IRole } from '../../types/models/roles-permissions';
import { getUserPrivileges } from '../../apis/privileges';

export enum PrivilegesActionTypes {
  FETCH_PRIVILEGES = 'FETCH_PRIVILEGES',
  FETCH_PRIVILEGES_SUCCESS = 'FETCH_PRIVILEGES_SUCCESS',
  FETCH_PRIVILEGES_FAILURE = 'FETCH_PRIVILEGES_FAILURE',
  DISMISS_PRIVILEGES = 'DISMISS_PRIVILEGES',
}

export interface FetchPrivilegesSuccessAction {
  type: typeof PrivilegesActionTypes.FETCH_PRIVILEGES_SUCCESS;
  payload: { roles: IRole[]; permissions: Permission[] };
}

export interface FetchPrivilegesAction {
  type: typeof PrivilegesActionTypes.FETCH_PRIVILEGES;
}

export interface FetchPrivilegesFailureAction {
  type: typeof PrivilegesActionTypes.FETCH_PRIVILEGES_FAILURE;
  error: CustomError;
}

export interface DismissPermissionsAction {
  type: typeof PrivilegesActionTypes.DISMISS_PRIVILEGES;
}

export type PrivilegesAction =
  | FetchPrivilegesSuccessAction
  | FetchPrivilegesFailureAction
  | DismissPermissionsAction
  | FetchPrivilegesAction;

export function dismissPrivileges() {
  return {
    type: PrivilegesActionTypes.DISMISS_PRIVILEGES,
  };
}

export function fetchPrivilegesAction(): AppThunk {
  return async (dispatch) => {
    dispatch({ type: PrivilegesActionTypes.FETCH_PRIVILEGES });
    try {
      const response = await getUserPrivileges();
      dispatch({
        type: PrivilegesActionTypes.FETCH_PRIVILEGES_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({ type: PrivilegesActionTypes.FETCH_PRIVILEGES_FAILURE, error });
    }
  };
}

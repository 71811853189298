type EventCalendarFilters = Record<string, any>;

function renderSeparator(isLast: boolean, separator = ',') {
  return isLast ? '' : separator;
}

function getFilterQueryField(key: keyof EventCalendarFilters, value: string[] | string) {
  const isEmptyArray = Array.isArray(value) && value.length === 0;

  if (!value || value === '' || isEmptyArray) {
    return '';
  }

  if (key === 'offset') {
    return `&offset=${value}`;
  }
  if (key === 'searchText') {
    return `&filter[name][like]=${value}`;
  }
  if (key === 'attendanceType') {
    return `&filter[attendance_type][like]=${value}`;
  }
  if (key === 'zipcode') {
    return `&filter[zipcode][eq]=${value}`;
  }
  if (key === 'month') {
    return `&filter[start_date_time][like]=${value}`;
  }

  const array = [...value];
  return array.reduce((result, item, index) => {
    const isLast = index + 1 === value.length;
    const orOperator = `&filter[start_date_time][like]=${index + 1}[logical]=or`;
    return `${result}&filter[start_date_time][like]=${item}${renderSeparator(isLast, orOperator)}`;
  }, '');
}

export function stringifyCollegeQueryFilter(filters: EventCalendarFilters) {
  return Object.keys(filters).reduce<string>((result: string, key: string) => {
    // Ignore signal key because it is an AbortSignal
    if (key === 'signal') {
      return result;
    }

    const value = filters[key as keyof EventCalendarFilters];
    return `${result}${getFilterQueryField(key as keyof EventCalendarFilters, value)}`;
  }, '');
}

import { RoleType } from './auth';

import { ISOString } from '../common';

export enum MessageType {
  GENERAL_QUESTION = 'generalQuestion',
  APPLICATION_STATUS = 'applicationStatus',
  APPLICATION_UPDATE = 'applicationUpdate',
  ALUMNI_QUESTION = 'alumniQuestion',
  CAREER_QUESTION = 'careerQuestion',
  DISBURSEMENT_QUESTION = 'disbursementQuestion',
  DOCUMENT_QUESTION = 'documentQuestion',
  DONATION_QUESTION = 'donationQuestion',
  ELIGIBILITY_QUESTION = 'eligibilityQuestion',
  RECOMMENDER_QUESTION = 'recommenderQuestion',
  SCHOLAR_CONFERENCES = 'scholarConferences',
  SCHOLARSHIP_READ_QUESTION = 'scholarshipReadQuestion',
  TECHNICAL_CONCERN = 'technicalConcern',
  VOLUNTEER_QUESTION = 'volunteerQuestion',
  YOUTH_LEADERSHIP_INSTITUTE = 'youthLeadershipInstitute',
  REPORT_USER = 'reportUser',
}

export enum CategoryType {
  ALUMNI = 'alumni',
  CAREER_SERVICES = 'careerServices',
  COLLEGE_CAMP = 'collegeCamp',
  DONATIONS = 'donations',
  DELETE_ACCOUNT = 'deleteAccount',
  FINANCIAL_AID = 'financialAid',
  FUNDRAISERS = 'fundraisers',
  HUMAN_RESOURCES = 'humanResources',
  HSF_ADVISORY_COUNCILS = 'hsfAdvisoryCouncils',
  HSF_CONFERENCES = 'hsfConferences',
  INFORMATION_TECHNOLOGY = 'informationTechnology',
  INTERVIEWERS = 'interviewers',
  PARTNERSHIPS = 'partnerships',
  RECOMMENDERS = 'recommenders',
  SCHOLAR_ADVOCATES = 'scholarAdvocates',
  SCHOLAR_CELEBRATIONS = 'scholarCelebrations',
  SCHOLARSHIPS = 'scholarships',
  VOLUNTEER = 'volunteer',
  YLI = 'yli',
  YLS = 'yls',
}

export enum ThreadStatus {
  OPEN = 'open',
  CLOSED = 'closed',
}

export interface IMessageThread {
  id: string;
  messageType: MessageType;
  categoryType: CategoryType;
  subject: string;
  unread: boolean;
  status: ThreadStatus;
  isArchived: boolean;
  messages: IMessage[];
}

export interface ReportedUser {
  firstName: string;
  lastName: string;
  tenantUserId: number;
}
export interface IAdminMessageThread extends IMessageThread {
  assignedCategoryAdmin: IAssignedCategoryAdmin | null;
  recipient: IRecipient;
  reportedUser: ReportedUser;
}

export interface IMessageImage {
  documentId: string;
  fileName: string;
}

export interface IMessage {
  id: string;
  body: string;
  date: ISOString;
  from: IFrom;
  image?: IMessageImage;
}

export interface IFrom {
  userId: string;
  firstName: string;
  lastName: string;
  roles: RoleType[];
}

export interface IRecipient {
  userId: string;
  tenantUserId: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string | null;
}

interface IAssignedCategoryAdmin {
  userId: string;
}

export interface ICreateMessageFormData {
  messageType: MessageType | any;
  subject: string;
  body: string;
}

export interface ICreateReportMessageFormData {
  messageType: MessageType | any;
  subject: string;
  body: string;
  reportedUserId: string;
}

export interface IAdminCreateMessageFormData extends ICreateMessageFormData {
  recipient?: Pick<IRecipient, 'userId'>;
  categoryType?: CategoryType;
}

export interface IReplyMessageFormData {
  body: string;
}

export interface IUpdateThreadData {
  messageType?: MessageType;
  categoryType?: CategoryType;
  status?: ThreadStatus;
  isArchived?: boolean;
}

export type UserTableDataSourceType = IMessageThread &
  Omit<IMessage, 'id' | 'from'> & {
    from: string; // This is now display name string
  };

export type AdminTableDataSourceType = Omit<IAdminMessageThread, 'messageType' | 'categoryType' | 'recipient'> &
  Omit<IMessage, 'id' | 'from'> & {
    messageType: any; // This is now label string
    categoryType: any; // This is now label string
    tenantUserId: string;
    name: string;
    email: string;
  };

export enum TabItem {
  UNASSIGNED = 'unassigned',
  ASSIGNED = 'assigned',
  ARCHIVED = 'archived',
  OPEN = 'open',
  CLOSED = 'closed',
  ALL = 'all',
  UNREAD = 'unread',
  ADMIN_UNASSIGNED = 'adminUnassigned',
}

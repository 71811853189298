// NOTE: Fails husky check, no unused vars
// import { StudentType } from './user-management';

export enum RoleType {
  STUDENT = 'student',
  SCHOLAR = 'scholar',
  SUPPORTER = 'supporter',
  READER = 'reader',
  INTERVIEWER = 'interviewer',
  RECOMMENDER = 'recommender',
  COUNSELOR = 'counselor',
  EDUCATOR = 'educator',
  ADMIN = 'admin',
  DISPATCHER_ADMIN = 'Customer Service Dispatcher',
  PARENT = 'parent',
  TECHNICIAN_ADMIN = 'technicianAdmin',
  SYSTEM_ADMIN = 'systemAdmin',
  ALUMNI_SELF_IDENTIFIED = 'Alumni Self Identified',
  HCPI_QUALIFIED_USER = 'HCPI Qualified User',
  ALUMNI_VERIFIED = 'Alumni Verified',
  FAO = 'FAO',
  DONOR = 'donor',
  MENTEE = 'mentee',
  MENTOR = 'mentor',
  COACH = 'coach',
  COACHEE = 'coachee',
  ADVISORY_COUNCIL_MEMBER = 'Advisory Council Member',
  SPONSOR = 'Sponsor',
  BOARD_OF_DIRECTOR = 'Board of Director',
  MANAGER_ADMIN = 'manager',
  READER_MANAGER = 'Reader Management',
  INTERVIEWER_MANAGER = 'Interview Management',
  PROGRAM_MENTOR = 'program mentor',
  PROGRAM_MENTEE = 'program mentee',
  ALC_SELF_IDENTIFIED = 'ALC Self Identified',
  LEADER_VERIFIED = 'Leader Verified',
}

export interface IUser {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  roles?: RoleType[];
  tenantUserId?: string;
}

export interface IEmailConfirmation {
  linkId: string;
  expiresAt: string;
}

export interface ISignUp {
  userId: string;
}

export interface ILogin {
  token: string;
}

export interface ISSOLogin extends ILogin {
  samlResponse: {
    entityEndpoint: string;
    context: string;
  };
}

export interface IForgetPasswordFormData {
  email?: string;
}

export interface ISignUpFormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  dob?: string;
  role?: string; // This is role `id`
  tos: string;
  currentClassLevel?: string;
}

export interface ILoginFormData {
  email: string;
  password: string;
  rememberMe: boolean;
}

export interface IVerificationDetails {
  linkId: string;
  userId: string;
}

export interface IResetPasswordFormData {
  password: string;
}

export interface IEmailUpdateConfirmation {
  linkId: string;
  userId: string;
  email: string;
}

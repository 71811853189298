import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

import { useI18NContext } from './context';

const Link = ({ isI18N = false, to, ...rest }: { isI18N?: boolean; to: string }) => {
  const { translateSlug } = useI18NContext();
  const toPath = isI18N ? translateSlug(to) : to;
  return <GatsbyLink {...rest} to={toPath} />;
};

export default Link;

import { produce } from 'immer';

import { LookupResponse, Option, OptionRecord } from '../../types/models/user-management';
import { LookupActionTypes } from '../actions/lookup';
import { LookupType } from '../../types/models/lookup';
import { ROLE_TYPE_LABELS } from '../../constants';
import { RoleType } from '../../types/models/auth';

function getInitialState(): OptionRecord {
  const genders: Option[] = [];
  const ethnicities: Option[] = [];
  const residencies: Option[] = [];
  const roles: Option[] = [];
  const identities: Option[] = [];
  const classLevels: Option[] = [];
  const enrollmentStatuses: Option[] = [];

  return {
    genders,
    ethnicities,
    residencies,
    roles,
    identities,
    classLevels,
    enrollmentStatuses,
  };
}
const initialState: OptionRecord = getInitialState();

const getLookupOptionList = (response: OptionRecord) => {
  const result: LookupResponse = {};
  Object.keys(response).forEach((key) => {
    const options = [] as Option[];
    response[key].forEach((item) => {
      if (item.description && item.id) {
        const label =
          key === 'roles' ? ROLE_TYPE_LABELS[item.description as RoleType] || item.description : item.description;
        options.push({ label, value: item.id });
      }
    });
    result[key] = options;
  });
  return result;
};

export default function lookup(state = initialState, { type, payload }: any) {
  return produce(state, (lookupState) => {
    if (type === LookupActionTypes.FETCH_ROLES_LOOKUP) {
      const lookup: LookupResponse = payload && getLookupOptionList(payload);
      if (lookup !== undefined) {
        lookupState.roles = lookup.roles;
      }
    } else if (type === LookupType.CLASS_LEVELS) {
      if (payload) {
        lookupState.classLevels = payload;
      }
    } else if (type === LookupType.ENROLLMENT_STATUSES) {
      if (payload) {
        lookupState.enrollmentStatuses = payload;
      }
    } else if (Object.values(LookupType).includes(type)) {
      if (payload) {
        lookupState[type] = payload;
      }
    }
  });
}

import produce from 'immer';

import { ApplicationAction, ApplicationActionTypes } from '../actions';

export interface ApplicationState {
  readonly ready: boolean;
}

const initialState: ApplicationState = {
  ready: false,
};

export default function application(state: ApplicationState = initialState, action: ApplicationAction) {
  return produce(state, (draft) => {
    switch (action.type) {
      case ApplicationActionTypes.READY:
        draft.ready = true;
        break;
      default:
        return;
    }
  });
}

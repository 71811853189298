import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { RootState } from '../reducers/index';

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, null, Action<string>>;

export enum ApplicationActionTypes {
  READY = 'READY',
}

export interface ApplicationReadyAction {
  type: typeof ApplicationActionTypes.READY;
}

export function appInitAction(): AppThunk {
  return (dispatch) => {
    dispatch({ type: ApplicationActionTypes.READY });
  };
}

export type ApplicationAction = ApplicationReadyAction;

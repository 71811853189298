import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import rootReducer, { RootState } from '../reducers';

export default function configureStore(initialState?: RootState) {
  const store: Store<RootState> = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(thunk)));

  return store;
}

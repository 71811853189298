import { AppThunk } from '.';

import { CustomError } from '../../types/models/error';
import { ITenantConfig } from '../../types/models/tenant-management';
import { getTenantConfig } from '../../apis/tenant';

export enum TenantConfigActionTypes {
  FETCH_TENANT_CONFIG = 'FETCH_TENANT_CONFIG',
  FETCH_TENANT_CONFIG_FAILURE = 'FETCH_TENANT_CONFIG_FAILURE',
  DISMISS_TENANT_CONFIG = 'DISMISS_TENANT_CONFIG',
}

export interface FetchTenantConfigAction {
  type: typeof TenantConfigActionTypes.FETCH_TENANT_CONFIG;
  payload: ITenantConfig;
}

export interface FetchTenantConfigFailureAction {
  type: typeof TenantConfigActionTypes.FETCH_TENANT_CONFIG_FAILURE;
  error: CustomError;
}

export interface DismissTenantConfigAction {
  type: typeof TenantConfigActionTypes.DISMISS_TENANT_CONFIG;
}

export type TenantConfigAction = FetchTenantConfigAction | FetchTenantConfigFailureAction | DismissTenantConfigAction;

export function dismissTenantConfig() {
  return {
    type: TenantConfigActionTypes.DISMISS_TENANT_CONFIG,
  };
}

export function fetchTenantConfigAction(): AppThunk {
  return async (dispatch) => {
    try {
      const response = await getTenantConfig();
      dispatch({
        type: TenantConfigActionTypes.FETCH_TENANT_CONFIG,
        payload: response,
      });
    } catch (error) {
      dispatch({ type: TenantConfigActionTypes.FETCH_TENANT_CONFIG_FAILURE, error });
    }
  };
}

export enum MentorsFilterTypes {
  setJobTypeValue = 'SET_JOB_TYPE_VALUE',
  setCollegeMajorAreaValue = 'SET_COLLEGE_MAJOR_AREA',
  setSkillsValue = 'SET_SKILLS_VALUE',
  setLocationValue = 'SET_LOCATION_VALUE',
  setSexValue = 'SET_SEX_VALUE',
  setEducationValue = 'SET_EDUCATION_VALUE',
  setProfessionalExperienceValue = 'SET_PROFESSIONAL_EXPERIENCE_VALUE',
  setVisibleAdvancedFilters = 'SET_VISIBLE_ADVANCED_FILTERS',
}

interface HandleJobTypeChangeAction {
  type: typeof MentorsFilterTypes.setJobTypeValue;
  payload: string;
}

interface HandleCollegeMajorAreaChangeAction {
  type: typeof MentorsFilterTypes.setCollegeMajorAreaValue;
  payload: string;
}

interface HandleSkillsChangeAction {
  type: typeof MentorsFilterTypes.setSkillsValue;
  payload: Array<string>;
}

interface HandleLocationChangeAction {
  type: typeof MentorsFilterTypes.setLocationValue;
  payload: string;
}

interface HandleSexChangeAction {
  type: typeof MentorsFilterTypes.setSexValue;
  payload: string;
}

interface HandleEducationChangeAction {
  type: typeof MentorsFilterTypes.setEducationValue;
  payload: string;
}

interface HandleProfessionalExperienceChangeAction {
  type: typeof MentorsFilterTypes.setProfessionalExperienceValue;
  payload: string;
}

interface ToggleVisibleAdvancedFiltersAction {
  type: typeof MentorsFilterTypes.setVisibleAdvancedFilters;
  payload: boolean;
}

export type MentorsFilterAction =
  | HandleJobTypeChangeAction
  | HandleCollegeMajorAreaChangeAction
  | HandleSkillsChangeAction
  | HandleLocationChangeAction
  | HandleSexChangeAction
  | HandleEducationChangeAction
  | HandleProfessionalExperienceChangeAction
  | ToggleVisibleAdvancedFiltersAction;

export interface MentorsFilterState {
  jobTypeValue: string;
  collegeMajorArea: string;
  skills: Array<string>;
  location: string;
  sex: string;
  education: string;
  professionalExperience: string;
  visibleAdvancedFilters: boolean;
}

export enum LookupType {
  BACHELOR_DEGREES = 'bachelor-degrees',
  CAREERS = 'careers',
  CITIZENSHIP = 'citizenship',
  CITIZENSHIP_OTHER = 'citizenship-other',
  CLASS_LEVELS = 'class-levels',
  COMPANY_TYPES = 'company-types',
  DOCTORATE_DEGREES = 'doctorate-degrees',
  EDUCATION_LEVELS = 'education-levels',
  EMPLOYMENT_EXPERIENCES = 'employment-experiences',
  EMPLOYMENT_STATUSES = 'employment-statuses',
  ENGINEERING_GRADUATE_SCHOOL_TYPES = 'engineering-graduate-school-types',
  ENROLLMENT_STATUSES = 'enrollment-statuses',
  ENROLLMENT_TYPES = 'enrollment-types',
  ETHNICITY = 'ethnicity',
  GENDER = 'gender',
  GPA_SCALES = 'gpa-scales',
  GPA_WEIGHTS = 'gpa-weights',
  GRADUATE_SCHOOL_TYPES = 'graduate-school-types',
  HERITAGE_AFRICAN_AMERICAN = 'heritage-african-american',
  HERITAGE_ASIAN = 'heritage-asian',
  HERITAGE_HISPANIC = 'heritage-hispanic',
  HERITAGE_NATIVE_TRIBES = 'heritage-native-tribes',
  HERITAGE_WHITE = 'heritage-white',
  HIGHSCHOOL = 'highschool',
  IDENTITIES = 'identities',
  INCOME_LEVELS = 'income-levels',
  IN_DEMAND_CAREERS = 'in-demand-careers',
  IN_DEMAND_MAJORS = 'in-demand-majors',
  INDUSTRIES = 'industries',
  JOB_FUNCTIONS = 'job-functions',
  LOCALE = 'locale',
  MAJOR_CATEGORIES = 'major-categories',
  MAJOR_CLASSIFICATION = 'major-classification',
  MAJOR_CLASSIFICATION_GROUPS = 'major-classification-groups',
  MASTER_DEGREES = 'master-degrees',
  OTHER_GRADUATE_SCHOOL_TYPES = 'other-graduate-school-types',
  PERSONAS = 'personas',
  RACES = 'races',
  RESIDENCY = 'residency',
  SCHOLARSHIP_NARRATIVES = 'scholarship-narratives',
  SECTORS = 'sectors',
  SENIORITIES = 'seniorities',
  SEX = 'sex',
  UNIVERSITY = 'university',
  US_MAJOR_AIRPORTS = 'us-major-airports',
  US_STATES = 'us-states',
  COHORTS = 'cohorts',
}

export type MentorshipActor = {
  id: string;
  tenantUserId: string;
  firstName: string;
  lastName: string;
  matchDate: string;
  completedDate: string;
  image: string;
  completedMilestones: string[];
  milestonesAmount: string;
  milestoneType: string;
  programName: string;
  programId: string;
  email?: string;
  phone?: string;
  isTerminated?: boolean;
  milestonesMet?: number;
};

export interface MentorBio {
  id: string;
  firstName: string;
  lastName: string;
  city: string | null;
  state: string | null;
  currentCompanyIndustries: any[] | null;
  jobTitle: string | null;
  imageUrl: string | null;
  skills?: string[];
  bachelorDegrees: any[] | null;
  masterDegrees: any[] | null;
  doctorateDegrees: any[] | null;
  mentorshipReason: string;
  employmentExperience: string | null;
  canSelect: boolean;
}

export interface MenteeBio {
  id: string;
  firstName: string;
  lastName: string;
  imageUrl?: string;
  skills?: string[];
  city: string | null;
  state: string | null;
  university: string | null;
  classLevel: string | null;
  highSchool: string | null;
  bachelorMajors: string[] | null;
  primaryMajor?: string;
}

export interface Milestone {
  id: string;
  name: string;
  description: string;
  milestoneType: string;
  tracks?: [];
}

export enum MentorBioTypes {
  setMentorData = 'SET_MENTOR_DATA',
}

interface HandleSetMentorData {
  type: typeof MentorBioTypes.setMentorData;
  payload: MentorBio;
}
export type MentorBioAction = HandleSetMentorData;

export interface MentorBioState {
  mentor: MentorBio | any;
}

export interface MenteeList {
  mentees: MentorshipActor[];
  activeMenteeCount: number;
  totalCount: number;
}

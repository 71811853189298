import { ISOString } from '../common';

export interface IDocument {
  id: string;
  fileName: string;
  documentType: DocumentType;
  date: ISOString;
}

export enum DocumentType {
  TRANSCRIPT = 'transcript',
  FINANCIAL_AID_AWARD_LETTER = 'financialAidAwardLetter',
  EMPLOYMENT_AUTHORIZATION_DOCUMENT = 'employmentAuthorizationDocument',
  STUDENT_AID_REPORT = 'studentAidReport',
  THANK_YOU_LETTER_VIDEO = 'thankYouLetterVideo',
  ENROLLMENT_VERIFICATION = 'enrollmentVerification',
  PUBLIC_EVENT_IMAGE = 'publicEventImage',
  IMAGE = 'image',
  HEADSHOT = 'headshot',
  RESUME = 'resume',
  SAT = 'sat',
  ACT = 'act',
  IB_EXAM = 'ibExam',
  AP_EXAM = 'apExam',
  GENERIC = 'generic',
}

export enum DocumentParamsType {
  UNIVERSITIES = 'universities',
  INSTITUTIONS = 'institutions',
}

export enum DocumentRequestType {
  ISIR = 'ISIR',
  CLEARINGHOUSE = 'ClearingHouse',
}

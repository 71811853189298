import { IEventType, IEventTopic, IEventLocation } from '../../types/models/program-management';

export interface CalendarEvent {
  id: number;
  type: number;
  start_date_time: string;
  end_date_time: string;
  event_timezone: {
    timezone_value: string;
  };
  name: string;
  image?: string | number;
  description: string;
  location?: string;
  online_location?: string;
  zipcode?: number;
  slug?: string;
  attendance_type: AttendanceType;
  eventbrite_link: string;
  eventbriteEvent?: boolean;
  directusEvent?: boolean;
}

export interface EventbriteEvent {
  name: string;
  eventbriteId: string;
  eventbriteUrl: string;
  currency: string;
  ianaTimezone: string;
  startDate: string;
  endDate: string;
  location: IEventLocation;
  type?: IEventType;
  topic?: IEventTopic;
  description: string;
  image: string;
}

export enum AttendanceType {
  ALL = '',
  ONLINE = 'online',
  IN_PERSON = 'inPerson',
}

const lodash = require('lodash');
const tenant = process.env.GATSBY_TENANT;
const tenantConfig = require(`${__dirname}/../../static/tenant-config.js`);
const config = tenantConfig.gatsbyConfig || {};
const clientRoutes = require(`${__dirname}/../../client-routes.js`);

const I18NProvider = {
  GOOGLE: 'google',
  CMS: 'cms',
};

const isProviderCMS = config.siteMetadata.i18n.provider === I18NProvider.CMS;
const isProviderGoogle = config.siteMetadata.i18n.provider === I18NProvider.GOOGLE;
const defaultLanguage = config.siteMetadata.i18n.default;
const languages = config.siteMetadata.i18n.languages;

const isClientRoute = (path) => {
  return clientRoutes.find((route) => path.startsWith(route.replace('*', '')));
};

const createPage = (pageConfig, createPageFn) => {
  if (isProviderGoogle) {
    const pageConfigClone = lodash.cloneDeep(pageConfig);
    // pageConfigClone.context.language = defaultLanguage;
    createPageFn(pageConfigClone);
  }
  if (isProviderCMS) {
    languages.forEach((language) => {
      const pageConfigClone = lodash.cloneDeep(pageConfig);

      if (language != defaultLanguage) {
        if (pageConfig.path === '/') {
          pageConfigClone.path = `/${language}`;
        } else {
          pageConfigClone.path = !isClientRoute(pageConfig.path) ? `/${language}${pageConfig.path}` : pageConfig.path;
        }
      }

      if (!pageConfigClone.context) {
        pageConfigClone.context = {};
      }
      pageConfigClone.context.language = language;
      createPageFn(pageConfigClone);
    });
  }
};

const translateEntity = (entity, currentLanguage) => {
  if (!entity || !entity.translations || !entity.translations[0] || !entity.translations[0].language) {
    console.error('Please provide language field for entity!');
    return {};
  }
  let translation = entity.translations.find((translation) => translation.language === currentLanguage);
  if (!translation) {
    translation = entity.translations.find((translation) => translation.language === defaultLanguage);
  }
  return translation;
};

const translateSlug = (slug, language) => {
  if (language && language !== defaultLanguage && slug === '/') {
    return `/${language}${slug}`;
  }
  if (!language || language === defaultLanguage || (slug && slug.includes('http')) || isClientRoute(slug)) {
    return slug;
  }
  return `/${language}${slug}`;
};

const createTranslationResolver = (translationType, translationEntity, sourceIdField = 'directusId') => {
  return {
    translation: {
      type: `${translationType}!`,
      args: { language: { type: 'String' } },
      resolve: async (source, args, context, info) => {
        const translations = await context.nodeModel.runQuery({
          query: { filter: { [`${translationEntity}`]: { directusId: { eq: source[sourceIdField] } } } },
          type: `${translationType}`,
          firstOnly: false,
        });
        const translation = translations.find((translation) => translation.language === args.language);
        const defaultTranslation = translations.find((translation) => translation.language === defaultLanguage);
        return translation || defaultTranslation;
      },
    },
  };
};

exports.isProviderCMS = isProviderCMS;
exports.isProviderGoogle = isProviderGoogle;
exports.languages = languages;
exports.defaultLanguage = defaultLanguage;
exports.createPage = createPage;
exports.translateEntity = translateEntity;
exports.translateSlug = translateSlug;
exports.createTranslationResolver = createTranslationResolver;
exports.isClientRoute = isClientRoute;

import React from 'react';
import { Menu, Dropdown } from 'antd';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CheckIcon from '@material-ui/icons/Check';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';

import { useI18NContext, utils as i18nUtils } from '../i18n';
import { DropdownItem } from '../components/common/header-styles';
import theme from '../theme';

const CMSLanguageMap: { [key: string]: string } = {
  en: 'English',
  es: 'Spanish',
};

const StyledMenu = styled(Menu)`
  width: 136px;
  padding: 0;
  overflow: hidden;

  .selectedLang {
    color: ${theme.colorsWhite};
    background-color: ${theme.colorsPrimaryBlue};
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      width: 20px;
      height: auto;
    }
  }
`;

const CMSTranslate = ({ languages, defaultLanguage }: { defaultLanguage: string; languages: string[] }) => {
  const { language, setLanguage } = useI18NContext();

  const languageOnClick = (e: any) => {
    const langCode = e.key;
    const path = window.location.pathname;

    if (path.includes(`/${langCode}/`) || langCode === language) {
      return;
    }

    setLanguage(langCode);

    // do not navigate if the current path is a client route.
    if (i18nUtils.isClientRoute(path) && path !== '/') {
      return;
    }

    if (langCode === defaultLanguage) {
      navigate(path.replace(`/${language}/`, '/'));
    } else {
      if (language === defaultLanguage) {
        navigate(`/${langCode}${path}`);
      } else {
        navigate(path.replace(`/${language}/`, `/${langCode}/`));
      }
    }
  };

  const menu = (
    <StyledMenu onClick={languageOnClick}>
      {languages.map(languageItem => (
        <Menu.Item className={language === languageItem ? 'selectedLang' : ''} key={languageItem}>
          {CMSLanguageMap[languageItem]}
          {language === languageItem && <CheckIcon />}
        </Menu.Item>
      ))}
    </StyledMenu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <DropdownItem className="ant-dropdown-link" onClick={e => e.preventDefault()}>
        {CMSLanguageMap[language]}
        <KeyboardArrowDownIcon />
      </DropdownItem>
    </Dropdown>
  );
};

export default CMSTranslate;

import { produce } from 'immer';

import { Permission, IRole, EXT_ROLE_TYPE_DICTIONARY, Role, RoleType } from './../../types/models/roles-permissions';

import { PrivilegesAction, PrivilegesActionTypes } from '../actions/privileges';
import { CustomError } from '../../types/models/error';

export interface PrivilegesState {
  readonly privilegesLoading: boolean;
  readonly userRoles?: Role[];
  readonly permissions?: Permission[];
  readonly error: CustomError | null;
  readonly isInternal?: boolean;
}

const roleMapper = (rolesObj: IRole[] | undefined) => {
  let newRolesArr: Role[] = [];
  if (rolesObj) {
    rolesObj.map(({ description }: IRole) => {
      const roleType = EXT_ROLE_TYPE_DICTIONARY[description];
      newRolesArr = [...newRolesArr, roleType];
    });
  }
  return newRolesArr;
};

const roleTypeMapper = (rolesObj: IRole[] | undefined) => {
  let isInternal = false;
  if (rolesObj) {
    isInternal = rolesObj.some(({ type }: IRole) => type === RoleType.INTERNAL);
  }
  return isInternal;
};

export const PRIVILEGE_DATA_LOCAL_STORAGE_KEY = 'privileges';

function getPrivilegesFromLocalStorage() {
  let privilegeData;
  if (typeof window !== 'undefined') {
    try {
      privilegeData = localStorage.getItem(PRIVILEGE_DATA_LOCAL_STORAGE_KEY);
      if (privilegeData) {
        privilegeData = JSON.parse(privilegeData);
      }
    } catch (err) {
      console.error(err);
    }
  }
  return privilegeData;
}

export function getInitialState(): PrivilegesState {
  const userPrivileges = getPrivilegesFromLocalStorage()?.privileges;
  const userPrivilegesData = userPrivileges || {};

  return {
    userRoles: userPrivilegesData?.userRoles,
    permissions: userPrivilegesData?.permissions,
    privilegesLoading: userPrivilegesData?.privilegesLoading,
    error: userPrivilegesData?.error,
    isInternal: roleTypeMapper(userPrivileges?.roles),
  };
}

const initialState: PrivilegesState = getInitialState();

export default function privileges(state = initialState, action: PrivilegesAction) {
  return produce(state, (draft) => {
    switch (action.type) {
      case PrivilegesActionTypes.FETCH_PRIVILEGES: {
        draft.error = null;
        draft.privilegesLoading = true;
        break;
      }
      case PrivilegesActionTypes.FETCH_PRIVILEGES_SUCCESS: {
        const privileges = action.payload;
        draft.userRoles = roleMapper(action.payload?.roles);
        draft.permissions = action.payload?.permissions;
        draft.error = null;
        draft.privilegesLoading = false;
        // store in localStorage
        localStorage.setItem(PRIVILEGE_DATA_LOCAL_STORAGE_KEY, JSON.stringify({ privileges }));
        draft.isInternal = roleTypeMapper(action.payload?.roles);
        break;
      }
      case PrivilegesActionTypes.FETCH_PRIVILEGES_FAILURE: {
        draft.userRoles = [];
        draft.permissions = [];
        draft.privilegesLoading = false;
        draft.error = action.error;
        break;
      }
      case PrivilegesActionTypes.DISMISS_PRIVILEGES: {
        draft.userRoles = [];
        draft.permissions = [];
        draft.privilegesLoading = false;
        draft.error = null;
        localStorage.removeItem(PRIVILEGE_DATA_LOCAL_STORAGE_KEY);
        draft.isInternal = false;
        break;
      }
    }
  });
}

import { ProfileActionType } from '../actions/profile';

const initialState = {
  user: {
    firstName: '',
    lastName: '',
  },
};

export default function profile(state: any = initialState, action: any) {
  switch (action.type) {
    case ProfileActionType.UPDATE_PROFILE:
      return { ...state, user: action.payload };
  }
  return state;
}

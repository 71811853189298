export enum RoleStatus {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export enum RoleType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export interface IRolesEntryList {
  id: string;
  description: string;
  isArchived: boolean;
}

export interface IRolesPermisionsDetailsFormData extends Omit<IRolesEntryList, 'id'> {
  id?: string;
}

export interface RoleListResult {
  items: any[];
}
export interface RoleDetailsPayload {
  description?: string;
  permissionGroups?: string[];
}

export interface RoleDetailsFormData extends RoleDetailsPayload {
  roleId?: string;
}

export interface IPermissionItem {
  description: string;
  displayName: string;
  name: string;
}

export interface PermissionGroup {
  categoryName: string;
  permissionGroups: IPermissionItem[];
  position: number;
}

export interface IPermissions {
  groupToPermissions: string;
  permissionGroups: PermissionGroup[];
  permissions: Permission[];
}

export interface ICategoryPermissions {
  [key: string]: IPermissionItem[];
}

export interface IRole {
  description: string;
  type: RoleType;
}
export interface PrivilegeResponse {
  permissions: Permission[];
  userRoles: IRole[];
}

export interface AdminMenuProps {
  canViewPrograms: boolean;
  canManageMessageCenter: boolean;
  canAssignMessages: boolean;
  canViewBroadcastCenter: boolean;
  canViewHsfUniversity: boolean;
  canViewAllUserAccounts: boolean;
  canManageRolesAndPermissions: boolean;
  canReviewDocVerification: boolean;
  canViewWellnessReports: boolean;
  canManageMentorMatch: boolean;
  canViewReports: boolean;
  canTriggerDeploy: boolean;
  isFinancialPortalEnable: boolean;
  hasAnyFinancialPortalPermission: boolean;
}

export enum Role {
  CUSTOMER_SERVICE_REPRESENTATIVE = 'customerServiceRepresentative',
  CUSTOMER_SERVICE_DISPATCHER = 'customerServiceDispatcher',
  SCHOLARSHIPS_PROGRAM_ADMIN = 'scholarshipsProgramAdmin',
  CAREER_SERVICES_PROGRAM_ADMIN = 'careerServicesProgramAdmin',
  LEGAL_EXECUTIVE = 'legalExecutive',
  PROGRAM_EXECUTIVE = 'programExecutive',
  EVENT_MANAGER = 'eventManager',
  MENTOR_MATCH_CAREER_COACH_PROGRAM_ADMIN = 'mentorMatchCareerProgramAdmin',
  COMMUNITIES_PROGRAM_ADMIN = 'communitiesProgramAdmin',
  SCHOLAR_ADVOCATE = 'scholarAdvocate',
  FINANCIAL_ADMINISTRATOR = 'financialAdministrator',
  DISBURSEMENT_MANAGER = 'disbursementManager',
  ACCOUNTING_ADMINISTRATOR = 'accountingAdministrator',
  COMMUNICATIONS_MANAGER = 'communicationsManager',
  READER_MANAGEMENT = 'readerManagement',
  INTERVIEW_MANAGEMENT = 'interviewManagement',
  DOCUMENT_VERIFICATION_TECHNICIAN = 'documentVerificationTechnician',
  DOCUMENT_VERIFICATION_MANAGER = 'documentVerificationManager',
  USER_ACCOUNT_MANAGEMENT = 'userAccountManagement',
  SYSTEM_ADMINISTRATOR = 'systemAdministrator',
  ADMIN = 'admin',
}

export const EXT_ROLE_TYPE_DICTIONARY: Record<string, Role> = {
  customerServiceRepresentative: Role.CUSTOMER_SERVICE_REPRESENTATIVE,
  customerServiceDispatcher: Role.CUSTOMER_SERVICE_DISPATCHER,
  scholarshipsProgramAdmin: Role.SCHOLARSHIPS_PROGRAM_ADMIN,
  careerServicesProgramAdmin: Role.CAREER_SERVICES_PROGRAM_ADMIN,
  legalExecutive: Role.LEGAL_EXECUTIVE,
  programExecutive: Role.PROGRAM_EXECUTIVE,
  eventManager: Role.EVENT_MANAGER,
  mentorMatchCareerProgramAdmin: Role.MENTOR_MATCH_CAREER_COACH_PROGRAM_ADMIN,
  communitiesProgramAdmin: Role.COMMUNITIES_PROGRAM_ADMIN,
  scholarAdvocate: Role.SCHOLAR_ADVOCATE,
  financialAdministrator: Role.FINANCIAL_ADMINISTRATOR,
  disbursementManager: Role.DISBURSEMENT_MANAGER,
  accountingAdministrator: Role.ACCOUNTING_ADMINISTRATOR,
  communicationsManager: Role.COMMUNICATIONS_MANAGER,
  readerManagement: Role.READER_MANAGEMENT,
  interviewManagement: Role.INTERVIEW_MANAGEMENT,
  documentVerificationTechnician: Role.DOCUMENT_VERIFICATION_TECHNICIAN,
  documentVerificationManager: Role.DOCUMENT_VERIFICATION_MANAGER,
  userAccountManagement: Role.USER_ACCOUNT_MANAGEMENT,
  systemAdministrator: Role.SYSTEM_ADMINISTRATOR,
  admin: Role.ADMIN,
};

// TODO: This Permission object has permissionGroups & permissions mixed.
export enum Permission {
  VIEW_FULL_PROGRAM_MANAGEMENT = 'VIEW_FULL_PROGRAM_MANAGEMENT',
  MANAGE_SCHOLARSHIP_PROGRAM = 'MANAGE_SCHOLARSHIP_PROGRAM',
  MANAGE_SURVEY_PROGRAM = 'MANAGE_SURVEY_PROGRAM',
  MANAGE_JOB_CAMPAIGN_PROGRAM = 'MANAGE_JOB_CAMPAIGN_PROGRAM',
  MANAGE_PRIVATE_EVENT_PROGRAM = 'MANAGE_PRIVATE_EVENT_PROGRAM',
  MANAGE_SELECTION_ALGORITHM = 'MANAGE_SELECTION_ALGORITHM',
  MANAGE_DELETE_SUBMISSION_FROM_PROGRAM = 'MANAGE_DELETE_SUBMISSION_FROM_PROGRAM',
  MANAGEMENT_READER = 'MANAGEMENT_READER',
  MANAGE_FLAGGED_ESSAYS = 'MANAGE_FLAGGED_ESSAYS',
  MANAGE_INTERVIEWER = 'MANAGE_INTERVIEWER',
  MANUALLY_ASSIGN_INTERVIEWER = 'MANUALLY_ASSIGN_INTERVIEWER',
  MANAGE_EVENT = 'MANAGE_EVENT',
  MANAGE_MENTOR = 'MANAGE_MENTOR',
  REVIEW_DOC_VERIFICATION = 'REVIEW_DOC_VERIFICATION',
  MANAGE_MESSAGE_CENTER = 'MANAGE_MESSAGE_CENTER',
  ASSIGN_MESSAGES = 'ASSIGN_MESSAGES',
  VIEW_BROADCASTS = 'VIEW_BROADCASTS',
  MANAGE_BROADCAST_CENTER = 'MANAGE_BROADCAST_CENTER',
  VIEW_USER_ACCOUNTS = 'VIEW_USER_ACCOUNTS',
  MANAGE_USER_ACCOUNTS = 'MANAGE_USER_ACCOUNTS',
  DELETE_USER_ACCOUNTS = 'DELETE_USER_ACCOUNTS',
  FLAG_USERS = 'FLAG_USERS',
  OVERRIDE_EMAIL_VERIFICATION = 'OVERRIDE_EMAIL_VERIFICATION',
  MANAGE_CONTACT_LOG = 'MANAGE_CONTACT_LOG',
  MANAGE_MENTOR_MACH = 'MANAGE_MENTOR_MACH',
  VIEW_COMMUNITY_AND_POSTS = 'VIEW_COMMUNITY_AND_POSTS',
  CREATE_COMMUNITY = 'CREATE_COMMUNITY',
  MANAGE_FLAGGED_POST = 'MANAGE_FLAGGED_POST',
  BAN_COMMUNITY_USER = 'BAN_COMMUNITY_USER',
  MANAGE_FST_APPROVAL_FUNDING_REQUEST = 'MANAGE_FST_APPROVAL_FUNDING_REQUEST',
  MANAGE_SND_APPROVAL_FUNDING_REQUEST = 'MANAGE_SND_APPROVAL_FUNDING_REQUEST',
  MANAGE_SCHOLAR_FUNDING_STATUS = 'MANAGE_SCHOLAR_FUNDING_STATUS',
  MANAGE_DISBURSE_FUNDS = 'MANAGE_DISBURSE_FUNDS',
  MANAGE_SCHOLAR_AND_FINANCIAL_UNIVERSITY = 'MANAGE_SCHOLAR_AND_FINANCIAL_UNIVERSITY',
  MANAGE_UNIVERSITY = 'MANAGE_UNIVERSITY',
  VIEW_SCHOLAR_WELLNESS_REPORTS = 'VIEW_SCHOLAR_WELLNESS_REPORTS',
  MANAGE_ROLE_AND_PERMISSION = 'MANAGE_ROLE_AND_PERMISSION',
  CAN_VIEW_ALL_PROGRAMS = 'CAN_VIEW_ALL_PROGRAMS',
  CAN_VIEW_ALL_STAGES = 'CAN_VIEW_ALL_STAGES',
  CAN_VIEW_USER_DETAIL = 'CAN_VIEW_USER_DETAIL',
  CAN_VIEW_SCHOLARSHIP_PROGRAMS = 'CAN_VIEW_SCHOLARSHIP_PROGRAMS',
  CAN_CREATE_SCHOLARSHIP_PROGRAMS = 'CAN_CREATE_SCHOLARSHIP_PROGRAMS',
  CAN_EDIT_SCHOLARSHIP_PROGRAMS = 'CAN_EDIT_SCHOLARSHIP_PROGRAMS',
  CAN_DELETE_SCHOLARSHIP_PROGRAMS = 'CAN_DELETE_SCHOLARSHIP_PROGRAMS',
  CAN_VIEW_SURVEY_PROGRAMS = 'CAN_VIEW_SURVEY_PROGRAMS',
  CAN_CREATE_SURVEY_PROGRAMS = 'CAN_CREATE_SURVEY_PROGRAMS',
  CAN_EDIT_SURVEY_PROGRAMS = 'CAN_EDIT_SURVEY_PROGRAMS',
  CAN_DELETE_SURVEY_PROGRAMS = 'CAN_DELETE_SURVEY_PROGRAMS',
  CAN_VIEW_JOB_CAMPAIGN_PROGRAMS = 'CAN_VIEW_JOB_CAMPAIGN_PROGRAMS',
  CAN_CREATE_JOB_CAMPAIGN_PROGRAMS = 'CAN_CREATE_JOB_CAMPAIGN_PROGRAMS',
  CAN_EDIT_JOB_CAMPAIGN_PROGRAMS = 'CAN_EDIT_JOB_CAMPAIGN_PROGRAMS',
  CAN_DELETE_JOB_CAMPAIGN_PROGRAMS = 'CAN_DELETE_JOB_CAMPAIGN_PROGRAMS',
  CAN_VIEW_PRIVATE_EVENT_PROGRAMS = 'CAN_VIEW_PRIVATE_EVENT_PROGRAMS',
  CAN_CREATE_PRIVATE_EVENT_PROGRAMS = 'CAN_CREATE_PRIVATE_EVENT_PROGRAMS',
  CAN_EDIT_PRIVATE_EVENT_PROGRAMS = 'CAN_EDIT_PRIVATE_EVENT_PROGRAMS',
  CAN_DELETE_PRIVATE_EVENT_PROGRAMS = 'CAN_DELETE_PRIVATE_EVENT_PROGRAMS',
  // for authorized programs
  CAN_VIEW_SELECTED_USERS_TO_PROGRAM = 'CAN_VIEW_SELECTED_USERS_TO_PROGRAM',
  CAN_EDIT_SELECTED_USERS_TO_PROGRAM = 'CAN_EDIT_SELECTED_USERS_TO_PROGRAM',
  CAN_VIEW_ALL_SUBMISSIONS = 'CAN_VIEW_ALL_DOCUMENTS',
  CAN_DELETE_SUBMISSIONS = 'CAN_DELETE_SUBMISSIONS',
  CAN_ASSIGN_READERS = 'CAN_ASSIGN_READERS',
  CAN_FLAG_ESSAYS = 'CAN_FLAG_ESSAYS',
  CAN_ASSIGN_INTERVIEWERS = 'CAN_ASSIGN_INTERVIEWERS',
  CAN_VIEW_ALL_DOCUMENTS = 'CAN_VIEW_ALL_DOCUMENTS',
  CAN_REVIEW_DOCUMENT_VERIFICATIONS = 'CAN_REVIEW_DOCUMENT_VERIFICATIONS',
  CAN_VIEW_NOT_STARTED_DOCUMENTS = 'CAN_VIEW_NOT_STARTED_DOCUMENTS',
  CAN_VIEW_EVENTS = 'CAN_VIEW_EVENTS',
  CAN_CREATE_EVENTS = 'CAN_CREATE_EVENTS',
  CAN_EDIT_EVENTS = 'CAN_EDIT_EVENTS',
  CAN_MANAGE_EVENTS = 'CAN_MANAGE_EVENTS',
  CAN_VIEW_MESSAGE_CENTER_MESSAGES = 'CAN_VIEW_MESSAGE_CENTER_MESSAGES',
  CAN_EDIT_MESSAGE_CENTER_MESSAGES = 'CAN_EDIT_MESSAGE_CENTER_MESSAGES',
  CAN_ASSIGN_MESSAGE_CENTER_MESSAGES = 'CAN_ASSIGN_MESSAGE_CENTER_MESSAGES',
  CAN_VIEW_BROADCASTS = 'CAN_VIEW_BROADCASTS',
  CAN_CREATE_BROADCASTS = 'CAN_CREATE_BROADCASTS',
  CAN_EDIT_BROADCASTS = 'CAN_EDIT_BROADCASTS',
  CAN_REMOVE_BROADCASTS = 'CAN_REMOVE_BROADCASTS',
  CAN_MANAGE_LEARNING_TRACKS = 'CAN_MANAGE_LEARNING_TRACKS',
  CAN_VIEW_ALL_USER_ACCOUNTS = 'CAN_VIEW_ALL_USER_ACCOUNTS',
  CAN_CREATE_USER_ACCOUNT = 'CAN_CREATE_USER_ACCOUNT',
  CAN_EDIT_USER_ACCOUNT = 'CAN_EDIT_USER_ACCOUNT',
  CAN_EDIT_ROLES_FOR_USER_ACCOUNT = 'CAN_EDIT_ROLES_FOR_USER_ACCOUNT',
  CAN_ACTIVATE_DEACTIVATE_USER_ACCOUNT = 'CAN_ACTIVATE_DEACTIVATE_USER_ACCOUNT',
  CAN_DELETE_USER_ACCOUNT = 'CAN_DELETE_USER_ACCOUNT',
  CAN_FLAG_USER_ACCOUNT = 'CAN_FLAG_USER_ACCOUNT',
  CAN_OVERRIDE_EMAIL_VERIFICATION_USER_ACCOUNT = 'CAN_OVERRIDE_EMAIL_VERIFICATION_USER_ACCOUNT',
  CAN_EDIT_USER_CONTACT_LOG_NOTE = 'CAN_EDIT_USER_CONTACT_LOG_NOTE',
  CAN_VIEW_MENTOR_MATCHES = 'CAN_VIEW_MENTOR_MATCHES',
  CAN_MANUALLY_MAKE_MENTOR_MATCH = 'CAN_MANUALLY_MAKE_MENTOR_MATCH',
  CAN_UNMATCH_MENTORS = 'CAN_UNMATCH_MENTORS',
  CAN_EDIT_MENTOR_MATCH = 'CAN_EDIT_MENTOR_MATCH',
  CAN_VIEW_COMMUNITIES_AND_POSTS = 'CAN_VIEW_COMMUNITIES_AND_POSTS',
  CAN_CREATE_COMMUNITIES = 'CAN_CREATE_COMMUNITIES',
  CAN_REVIEW_AND_APPROVE_FLAGGED_POSTS = 'CAN_REVIEW_AND_APPROVE_FLAGGED_POSTS',
  CAN_BAN_USER_IN_COMMUNITY = 'CAN_BAN_USER_IN_COMMUNITY',
  CAN_VIEW_FST_FUNDING_REQUEST = 'CAN_VIEW_FST_FUNDING_REQUEST', // different read access for different groups
  CAN_APPROVE_FST_FUNDING_REQUEST = 'CAN_APPROVE_FST_FUNDING_REQUEST',
  CAN_VIEW_SND_FUNDING_REQUEST = 'CAN_VIEW_SND_FUNDING_REQUEST',
  CAN_APPROVE_SND_FUNDING_REQUEST = 'CAN_APPROVE_SND_FUNDING_REQUEST',
  CAN_VIEW_SCHOLAR_FUNDING_STATUS = 'CAN_VIEW_SCHOLAR_FUNDING_STATUS',
  CAN_UPDATE_SCHOLAR_FUNDING_STATUS = 'CAN_UPDATE_SCHOLAR_FUNDING_STATUS',
  CAN_VIEW_FUNDS = 'CAN_VIEW_FUNDS',
  CAN_DISBURSE_FUNDS = 'CAN_DISBURSE_FUNDS',
  CAN_VIEW_SCHOLARS = 'CAN_VIEW_SCHOLARS',
  CAN_MANAGE_SCHOLARS = 'CAN_MANAGE_SCHOLARS',
  CAN_VIEW_SCHOLAR_UNIVERSITY = 'CAN_VIEW_SCHOLAR_UNIVERSITY',
  CAN_MANAGE_SCHOLAR_UNIVERSITY = 'CAN_MANAGE_SCHOLAR_UNIVERSITY',
  CAN_VIEW_WELLNESS_REPORTS = 'CAN_VIEW_WELLNESS_REPORTS',
  CAN_VIEW_REPORTS = 'CAN_VIEW_REPORTS',
  CAN_VIEW_ROLES = 'CAN_VIEW_ROLES',
  CAN_EDIT_ROLES = 'CAN_EDIT_ROLES',
  CAN_CREATE_ROLES = 'CAN_CREATE_ROLES',
  CAN_VIEW_ONLY_READER_STAGE_DETAILS = 'CAN_VIEW_ONLY_READER_STAGE_DETAILS',
  CAN_VIEW_ONLY_INTERVIEWER_STAGE_DETAILS = 'CAN_VIEW_ONLY_INTERVIEWER_STAGE_DETAILS',
  CAN_VIEW_SELECTION_STATUSES = 'CAN_VIEW_SELECTION_STATUSES',
  CAN_WRITE_BY_SELECTION_STATUSES = 'CAN_WRITE_BY_SELECTION_STATUSES',
  CAN_TRIGGER_DEPLOY = 'CAN_TRIGGER_DEPLOY',
  // Financial Portal PERMISSIONS
  CAN_CREATE_FERPA_LEGAL_STATEMENT = 'CAN_CREATE_FERPA_LEGAL_STATEMENT',
  CAN_MANAGE_FERPA_LEGAL_STATEMENT = 'CAN_MANAGE_FERPA_LEGAL_STATEMENT',
  CAN_VIEW_SCHOLAR_FUNDING_STATUS_ADMINISTER_ENROLLMENT_VERIFICATION = 'CAN_VIEW_SCHOLAR_FUNDING_STATUS_ADMINISTER_ENROLLMENT_VERIFICATION',
  CAN_UPDATE_SCHOLAR_FUNDING_STATUS_ADMINISTER_ENROLLMENT_VERIFICATION = 'CAN_UPDATE_SCHOLAR_FUNDING_STATUS_ADMINISTER_ENROLLMENT_VERIFICATION',
  CAN_VIEW_DISBURSEMENT_ACADEMIC_YEARS = 'CAN_VIEW_DISBURSEMENT_ACADEMIC_YEARS',
  CAN_MANAGE_DISBURSEMENT_ACADEMIC_YEARS = 'CAN_MANAGE_DISBURSEMENT_ACADEMIC_YEARS',
  CAN_ONBOARD_FINANCIAL_AID_OFFICER = 'CAN_ONBOARD_FINANCIAL_AID_OFFICER',
  CAN_MANAGE_SCHOLAR_DEFERMENT_ACCESS = 'CAN_MANAGE_SCHOLAR_DEFERMENT_ACCESS',
  CAN_MANAGE_ONBOARDING_SCHOLAR_UNIVERSITY_FOR_DISBURSEMENT = 'CAN_MANAGE_ONBOARDING_SCHOLAR_UNIVERSITY_FOR_DISBURSEMENT',
  MANAGE_SCHOLAR_TRANSFER_REQUEST = 'MANAGE_SCHOLAR_TRANSFER_REQUEST',
  CAN_UPLOAD_REUPLOAD_RESOURCES_FOR_SCHOLAR_AND_FAO = 'CAN_UPLOAD_REUPLOAD_RESOURCES_FOR_SCHOLAR_AND_FAO',
  MANAGE_JOURNAL_ENTRY = 'MANAGE_JOURNAL_ENTRY',
}

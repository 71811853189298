import { LookupType } from './lookup';

import { IFormPayload, IFormState, FormStatus, RequestStatus, IFormSchema } from '../form';
import { tenantLabel } from '../../siteContent';
import { ISOString } from '../common';

export enum StudentType {
  HIGH_SCHOOL_FRESHMAN = 'highSchoolFreshman',
  HIGH_SCHOOL_SOPHOMORE = 'highSchoolSophomore',
  HIGH_SCHOOL_JUNIOR = 'highSchoolJunior',
  HIGH_SCHOOL_SENIOR = 'highSchoolSenior',
  COLLEGE_FRESHMAN = 'collegeFreshman',
  COLLEGE_SOPHOMORE = 'collegeSophomore',
  COLLEGE_JUNIOR = 'collegeJunior',
  COLLEGE_SENIOR = 'collegeSenior',
  FIRST_YEAR_MASTERS = 'firstYearMasters',
  SECOND_YEAR_MASTERS = 'secondYearMasters',
  THIRD_YEAR_MASTERS = 'thirdYearMasters',
  FIRST_YEAR_DOCTORATE = 'firstYearDoctorate',
  SECOND_YEAR_DOCTORATE = 'secondYearDoctorate',
  THIRD_YEAR_DOCTORATE = 'thirdYearDoctorate',
  FOURTH_YEAR_DOCTORATE = 'fourthYearDoctorate',
  FIFTH_YEAR_DOCTORATE = 'fifthYearDoctorate',
  SIXTH_YEAR_DOCTORATE = 'sixthYearDoctorate',
  SEVENTH_YEAR_DOCTORATE = 'seventhYearDoctorate',
  EIGHTH_YEAR_DOCTORATE = 'eighthYearDoctorate',
}

export enum Ethnicity {
  AFRICAN_AMERICAN = 'african_american',
  NATIVE_AMERICAN = 'native_american',
  ASIAN = 'asian',
  HISPANIC = 'hispanic',
  WHITE = 'white',
}

export enum ReasonsToRemove {
  FAMILY_EMERGENCY = 'familyEmergency',
  PERSONAL_HEALTH_ISSUE = 'personalHealthIssue',
  CODE_OF_CONDUCT = 'codeOfConduct',
  INELIGIBLE = 'ineligible',
  APPROVED_SCHEDULING_CONFLICT = 'approvedSchedulingConflict',
  UN_APPROVED_SCHEDULING_CONFLICT = 'unApprovedSchedulingConflict',
}

export interface ApiResponse<T> {
  result: T;
  totalCount: number;
}

export interface StageAssociatedUser {
  userId: string;
  programId: string;
  stageId: string;
  requestId: string;
  timezone: string;
  requested: string;
  formStatus: FormStatus;
  requestStatus: RequestStatus;
  interviewType: string;
  studentSchool: string;
  studentLocation: string;
  userProfile: IUserProfile;
  formSchema?: IFormSchema;
  formPayload?: IFormPayload;
  documentId: string;
}

export interface IUserProfile {
  userId: string;
  roles: string[];
  tenantUserId?: number;
  dob?: string;
  firstName?: string;
  middleName?: string;
  ethnicities?: string[];
  lastName?: string;
  gender?: string;
  residency?: string;
  isHispanic?: boolean;
  graduationDate?: ISOString;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  country?: string;
  zipcode?: number;
  phone?: string;
  email?: string;
  previousWork?: string;
  currentWork?: string;
  optInCommunication?: boolean;
  created_at?: ISOString;
  updated_at?: ISOString;
  profilePayload?: IFormPayload;
  profileState?: IFormState;
  attendeeStatus?: string;
  applicantRating?: string;
  name?: string;
  invitationDate?: string;
  active?: boolean;
  isFlagged?: boolean;
  isActive?: boolean;
  sex?: string;
  race?: string[];
  currentClassLevel?: string;
  primaryMajor?: string;
  citizenshipStatus?: string;
  currentEnrollmentStatus?: string;
  currentEnrollmentType?: string;
  currentUniversityName?: string;
  secondaryMajor?: string;
  studentType?: string;
  graduateSchoolType?: string;
  otherMajorsConsidered?: string[];
  minor?: string;
  major?: string;
  mobilePhoneNumber?: string;
  workPhone?: string;
  //BE inconsistence: mobilePhoneNumber vs. phoneNumbers fields
  phoneNumbers?: string;
}

export interface IUserProfileFlagged extends IUserProfile {
  flagged?: boolean;
}

export interface IOption {
  label: string;
  value: number | boolean | string;
}

export interface IRace {
  id: string;
  description: string;
}

export interface IResidency {
  id: string;
  description: string;
}

export interface IGender {
  id: string;
  description: string;
}

export interface IRole {
  id: string;
  description: string;
}

export type Option = {
  label: string;
  value: string;
};

export type LookupOption = {
  id?: string;
  displayText?: string;
  description?: string;
  label: string;
  value: string;
};

export type OptionRecord = Record<string, LookupOption[]>;

export type LookupResponse = Record<string, Option[]>;

export type ElementSchemaForProfile = {
  id: string;
  name: string;
  title?: string;
  placeHolder?: string; // input, select only
  options?: IOption[]; // select, checkboxgroup, radiogroup only
  maxLength?: number; // input only
  hasCheckAll?: boolean;
  disable?: any[];
  type: string;
  panel: string;
  hidden?: boolean;
  value?: string | null | number;
  precision?: number;
  min?: number;
};

export interface IUserManagementProfile {
  userId: string;
  tenantUserId?: number;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  role: string;
  phone?: string;
  email?: string;
  active?: boolean;
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface IUserManagementProfileDetails {
  userId: string;
  firstName?: string;
  lastName?: string;
  currentEmail?: string;
  email: string;
  roleType: boolean;
  role?: string[];
  scholarStatus?: string;
  scholarSupportStatus?: string;
}

export interface AddUserFormData {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  eventRoles?: string;
  roleType?: string;
  roles: string[];
  roleIds: string[];
  scholarStatus?: string;
  scholarSupportStatus?: string;
  donorCompanyId?: string;
}

export interface EditUserFormData {
  userId: string;
  name: string;
  email: string;
  roleType?: string;
  roles?: string[];
  roleIds?: string[];
  scholarStatus?: string;
  scholarSupportStatus?: string;
  donorCompanyId?: string;
  loginAction?: string;
  canUpdateRoles?: boolean;
  canUpdateAditionalRoleData?: boolean;
  role: string;
}

export interface IUserBadge {
  id: string;
  name: string;
  imageId: string;
  imageUrl?: string | null;
  eventRoles: string[];
}

export interface IUserBadgeResponse {
  items: IUserBadge[];
  totalCount: number;
}

export enum RoleTypeItem {
  EXTERNAL = 'external',
  INTERNAl = 'internal,',
}

export enum UserManagementView {
  DEFAULT = 'default',
  READER_VIEW = 'readerView',
  INTERVIEWER_VIEW = 'interviewerView',
  MENTOR_VIEW = 'mentorView',
  SCHOLAR_VIEW = 'scholarView',
  CUSTOM_VIEW = 'customView',
}

export enum TableColumn {
  TENANT_USER_ID = 'tenantUserId',
  SCHOLAR_STATUS = 'scholarStatus',
  SCHOLAR_SUPPORT_STATUS = 'scholarSupportStatus',
  // COHORT = 'Cohort',
  LEGAL_NAME = 'legalName',
  FIRST_NAME = 'firstName',
  MIDDLE_NAME = 'middleName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  DOB = 'dob',
  MOBILE_PHONE = 'mobilePhoneNumber',
  GENDER = 'gender',
  SEX = 'sex',
  RACE = 'race',
  ROLE = 'roles',
  // ASSIGNED_IDENTITY = 'Assigned Identity',
  IDENTITY = 'ethnicities',
  HISPANIC = 'hispanic',
  HERITAGE = 'heritage',
  HISPANIC_HERITAGE = 'hispanicHeritage',
  ASIAN_HERITAGE = 'asianHeritage',
  AMERICAN_INDIAN_TRIBE = 'americanIndianTribe',
  AFRICAN_AMERICAN_HERITAGE = 'africanAmericanHeritage',
  WHITE_HERITAGE = 'whiteHeritage',
  CITIZENSHIP = 'citizenshipStatus',
  // CITIZENSHIP_OTHER = 'Citizenship Other',
  INCOME_LEVEL = 'incomeLevel',
  // HOUSEHOLD_INCOME = 'Household Income',
  // LOW_INCOME = 'Low Income',
  FIRST_GEN = 'firstGen',
  FIRST_GEN_US = 'firstGenUs',
  FIRST_GEN_CALCULATED = 'firstGenCalculated',
  PARENT_FIRST = 'parent1EducationLevel',
  PARENT_SECOND = 'parent2EducationLevel',
  HOMETOWN = 'hometownName',
  HIGH_SCHOOL_ATENDING = 'highSchool',
  HIGH_SCHOOL_LOCATION = 'highSchoolLocation',
  HIGH_SCHOOL_GRADUATION = 'highSchoolGraduationYear',
  // CURRENT_HOME_ADDRESS = 'Current Home Address',
  // MAILING_ADDRESS = 'Mailing Address',
  // CURRENT_ADDRESS_MSA = 'Current Address MSA',
  CURRENT_UNIVERSITY = 'currentUniversityName',
  // CURRENT_SCHOOL_RATING = "currentSchoolRating",
  // ZIP_AND_MSA = 'Zip and MSA',
  // CURRENT_SCHOOL_LEVEL = 'Current School Level',
  CURRENT_ENROLLMENT_TYPE = 'currentEnrollmentType',
  CURRENT_ENROLLMENT_STATUS = 'currentEnrollmentStatus',
  // CURRENT_ACADEMIC_YEAR = 'Current Academic Year',
  CURRENT_CLASS_LEVEL = 'currentClassLevel',
  EXPECTED_GRADUATION_DATE = 'expectedGraduationDate',
  GRAD_SCHOOL_TYPE = 'graduateSchoolType',
  OTHER_GRAD_SCHOOL_TYPE = 'otherGraduateSchool',
  ENGINEERING_GRAD_SCHOOL = 'engineeringGradSchoolType',
  // GRADUATE_SCHOOL_PROGRAM = 'Graduate School/Program',
  CURRENT_GPA = 'currentGpa',
  GPA_WEIGHT_SCALE = 'gpaWeightScale',
  GPA_SCALE = 'gpaScale',
  DECLARED_MAJOR = 'declaredMajor',
  MAJOR = 'primaryMajor',
  SECOND_MAJOR = 'secondaryMajor',
  MINOR = 'minor',
  INTERESTED_MAJORS = 'otherMajorsConsidered',
  LAST_SCHOLARSHIP_APPLICATION_DATE = 'dateOfLastScholarshipApplication',
  LAST_VERIFIED_EV_DATE = 'dateOfLastVerifiedEV',
  LAST_VERIFIED_EV_ACADEMIC_YEAR = 'academicYearOfLastVerifiedEV',
  LAST_VERIFIED_EV_SCHOOL = 'schoolOfLastVerifiedEV',
  LAST_VERIFIED_ENROLLMENT_STATUS = 'lastVerifiedCurrentEnrollmentStatus',
  LAST_VERIFIED_TRANSCRIPT_DATE = 'dateOfLastVerifiedTranscript',
  LAST_VERIFIED_TRANSCRIPT_ACADEMIC_YEAR = 'academicYearOfLastVerifiedTranscript',
  LAST_VERIFIED_TRANSCRIPT_SCHOOL = 'schoolOfLastVerifiedTranscript',
  LAST_VERIFIED_GPA = 'lastVerifiedGPA',
  LAST_VERIFIED_GPA_SCALE = 'lastVerifiedGPAScale',
  LAST_VERIFIED_GPA_WEIGHT = 'lastVerifiedGPAWeight',
  LAST_VERIFIED_EAD_DATE = 'dateOfLastVerifiedEAD',
  LAST_VERIFIED_EAD_EXPIRATION_DATE = 'expirationDateOfLastVerifiedEAD',
  LAST_VERIFIED_SAR_DATE = 'dateOfLastVerifiedSAR',
  LAST_VERIFIED_EFC_AMOUNT = 'lastVerifiedEFCAmount',
  LAST_VERIFIED_FAAL_DATE = 'dateOfLastVerifiedFAAL',
  LAST_VERIFIED_FAAL_SCHOOL = 'schoolOfLastVerifiedFAAL',
  LAST_VERIFIED_TOTAL_ATTENDANCE_COST = 'lastVerifiedTotalCostOfAttendance',
  // COMPLETED = 'Completed',
  // NUMBER_OF_MENTORS = 'Number of Mentors',
  SCHOLARSHIP_AWARDED_TOTAL = 'totalNumberOfScholarshipsAwards',
  SCHOLARSHIP_AWARDED_SUM = 'sumOfAllScholarshipAwards',
  // ATTENDED = 'Attended',
  // FAIRS_ATTENDED = 'Fairs Attended',
  // EVENTS_ATTENDED_NUMBER = 'Number of Events Attended',
  // SCHOLARSHIPS_AWARDED = 'Scholarships Awarded',
  // CONFERENCES_ATTENDED = 'Conferences Attended',
  // JOB_CAMPAIGNS = 'Job Campaigns',
  // DISBURSEMENT_HISTORY = 'Disbursement History',
  WORK_PHONE = 'workPhone',
  WORK_EMAIL = 'workEmail',
  CURRENT_EMPLOYMENT_STATUS = 'currentEmploymentStatus',
  YEARS_OF_EXPERIENCE = 'yearsOfExperience',
  CURRENT_EMPLOYER = 'currentEmployer',
  COMPANY_INDUSTRY = 'companyIndustries',
  COMPANY_SECTOR = 'companySectors',
  COMPANY_TYPE = 'companyType',
  YEARS_AT_COMPANY = 'yearsAtCompany',
  SENIORITY_LEVEL = 'seniorityLevel',
  JOB_TITLE = 'jobTitle',
  JOB_FUNCTION = 'jobFunctions',
  YEARS_IN_POSITION = 'yearsInPosition',
  BACHELORS_DEGREE_EARNED = 'bachelorDegreesEarned',
  BACHELORS_MAJOR = 'bachelorMajors',
  UNDERGRADUATE_SCHOOL = 'bachelorUndergraduateSchool',
  BACHELORS_GRADUATION_YEAR = 'bachelorYearOfGraduation',
  MASTERS_DEGREE_EARNED = 'masterDegreesEarned',
  MASTERS_MAJOR = 'masterDegreeMajors',
  MASTERS_SCHOOL = 'masterDegreeSchools',
  MASTERS_GRADUATION_YEAR = 'masterDegreeYearsOfGraduation',
  DOCTORS_DEGREE_EARNED = 'doctorateDegreesEarned',
  DOCTORS_MAJOR = 'doctorateDegreeMajors',
  DOCTORS_SCHOOL = 'doctorateDegreeSchools',
  DOCTORS_GRADUATION_YEAR = 'doctorateDegreeYearsOfGraduation',
  STRENGTH_LEARNER = 'strengthLifeLongLearner',
  STRENGTH_BUILDING = 'strengthBuildingRelationships',
  STRENGTH_CAREER_PLANNING = 'strengthCareerPlanning',
  STRENGTH_DECISION_MAKING = 'strengthDecisionMaking',
  STRENGTH_DELIVERING_RESULTS = 'strengthDeliveringResults',
  STRENGTH_EXPLORING = 'strengthExploring',
  STRENGTH_GOAL_SETTING = 'strengthGoalSetting',
  STRENGTH_LEADERSHIP = 'strengthLeadership',
  STRENGTH_PERSONAL_BRAND = 'strengthPersonalBrand',
  STRENGTH_PAYING_FORWARD = 'strengthPayingItForward',
  STRENGTH_PERSONAL_FINANCE = 'strengthPersonalFinances',
  STRENGTH_PERSONAL_WELLNESS = 'strengthPersonalWellness',
  STRENGTH_PREP_GRAD = 'strengthPreparingGraduateSchool',
  STRENGTH_PROFESSIONAL = 'strengthProfessionalDevelopment',
  STRENGTH_SELF_ASSESSMENT = 'strengthSelfAssessment',
  STRENGTH_INTERVIEWING = 'strengthInterviewing',
  STRENGTH_TIME_MANAGEMENT = 'strengthTimeManagement',
  STRENGTH_WRITING_RESUME = 'strengthWritingResume',
  DATE_OF_LAST_UPDATE_OF_CURRENT_SCHOOL = 'dateOfLastUpdateOfCurrentSchool',
  CERTIFICATIONS = 'certifications',
}

export const CUSTOM_TABLE_COLUMNS_LABELS = new Map<TableColumn, string>([
  [TableColumn.TENANT_USER_ID, `${tenantLabel} ID`],
  [TableColumn.SCHOLAR_STATUS, 'Scholar Status'],
  [TableColumn.SCHOLAR_SUPPORT_STATUS, 'Scholar Support Status'],
  // [TableColumn.COHORT, 'Cohort'],
  [TableColumn.LEGAL_NAME, 'Legal Name'],
  [TableColumn.FIRST_NAME, 'First Name'],
  [TableColumn.MIDDLE_NAME, 'Middle Name'],
  [TableColumn.LAST_NAME, 'Last Name'],
  [TableColumn.EMAIL, 'Email Address'],
  [TableColumn.DOB, 'DOB'],
  [TableColumn.MOBILE_PHONE, 'Mobile Phone Number'],
  [TableColumn.GENDER, 'Gender'],
  [TableColumn.SEX, 'Sex'],
  [TableColumn.RACE, 'Race'],
  // [TableColumn.ASSIGNED_IDENTITY, 'Assigned Identity'],
  [TableColumn.IDENTITY, 'Indentity'],
  [TableColumn.HISPANIC, 'Hispanic Y/N'],
  [TableColumn.HISPANIC_HERITAGE, 'Hispanic Heritage'],
  [TableColumn.ASIAN_HERITAGE, 'Asian Heritage'],
  [TableColumn.AMERICAN_INDIAN_TRIBE, 'American Indian Tribe'],
  [TableColumn.AFRICAN_AMERICAN_HERITAGE, 'African American Heritage'],
  [TableColumn.WHITE_HERITAGE, 'White Heritage'],
  [TableColumn.CITIZENSHIP, 'Citizenship'],
  // [TableColumn.CITIZENSHIP_OTHER, 'Citizenship Other'],
  [TableColumn.INCOME_LEVEL, 'Income Level'],
  // [TableColumn.HOUSEHOLD_INCOME, 'Household Income'],
  // [TableColumn.LOW_INCOME, 'Low Income'],
  [TableColumn.FIRST_GEN, 'First Gen'],
  [TableColumn.FIRST_GEN_US, 'First Gen US'],
  [TableColumn.FIRST_GEN_CALCULATED, 'First Gen Calculated'],
  [TableColumn.PARENT_FIRST, 'Parent 1 Education Level'],
  [TableColumn.PARENT_SECOND, 'Parent 2 Education Level'],
  [TableColumn.HOMETOWN, 'Hometown'],
  [TableColumn.HIGH_SCHOOL_ATENDING, 'High School Atending'],
  [TableColumn.HIGH_SCHOOL_LOCATION, 'High School Location'],
  [TableColumn.HIGH_SCHOOL_GRADUATION, 'Year Graduated High School'],
  // [TableColumn.CURRENT_HOME_ADDRESS, 'Current Home Address'],
  // [TableColumn.MAILING_ADDRESS, 'Mailing Address'],
  // [TableColumn.CURRENT_ADDRESS_MSA, 'Current Address MSA'],
  [TableColumn.CURRENT_UNIVERSITY, 'Current University'],
  // [TableColumn.CURRENT_SCHOOL_RATING, "Current School's Ranking"],
  // [TableColumn.ZIP_AND_MSA, 'Zip and MSA'],
  // [TableColumn.CURRENT_SCHOOL_LEVEL, 'Current School Level'],
  [TableColumn.CURRENT_ENROLLMENT_TYPE, 'Current Enrollment Type'],
  [TableColumn.CURRENT_ENROLLMENT_STATUS, 'Current Enrollment Status'],
  // [TableColumn.CURRENT_ACADEMIC_YEAR, 'Current Academic Year'],
  [TableColumn.CURRENT_CLASS_LEVEL, 'Current Class Level'],
  [TableColumn.EXPECTED_GRADUATION_DATE, 'Expected Graduation Date'],
  [TableColumn.GRAD_SCHOOL_TYPE, 'Grad School Type'],
  [TableColumn.OTHER_GRAD_SCHOOL_TYPE, 'Other Grad School Type'],
  [TableColumn.ENGINEERING_GRAD_SCHOOL, 'Engineering Grad School'],
  // [TableColumn.GRADUATE_SCHOOL_PROGRAM, 'Graduate School/Program'],
  [TableColumn.CURRENT_GPA, 'Current GPA'],
  [TableColumn.GPA_WEIGHT_SCALE, 'GPA Weight Scale'],
  [TableColumn.GPA_SCALE, 'GPA Scale'],
  [TableColumn.DECLARED_MAJOR, 'Declared Major'],
  [TableColumn.MAJOR, 'Major'],
  [TableColumn.SECOND_MAJOR, 'Second Major'],
  [TableColumn.MINOR, 'Minor'],
  [TableColumn.INTERESTED_MAJORS, 'Interested Major(s)'],
  [TableColumn.LAST_SCHOLARSHIP_APPLICATION_DATE, 'Date of Last Scholarship Application '],
  [TableColumn.LAST_VERIFIED_EV_DATE, 'Date of Last Verified EV'],
  [TableColumn.LAST_VERIFIED_EV_ACADEMIC_YEAR, 'Academic Year of the Last Verified EV'],
  [TableColumn.LAST_VERIFIED_EV_SCHOOL, 'School of the Last Verified EV'],
  [TableColumn.LAST_VERIFIED_ENROLLMENT_STATUS, 'Last Verified Current Enrollment Status'],
  [TableColumn.LAST_VERIFIED_TRANSCRIPT_DATE, 'Date of Last Verified Transcript'],
  [TableColumn.LAST_VERIFIED_TRANSCRIPT_ACADEMIC_YEAR, 'Academic Year of Last Verified Transcript'],
  [TableColumn.LAST_VERIFIED_TRANSCRIPT_SCHOOL, 'School of Last Verified Transcript'],
  [TableColumn.LAST_VERIFIED_GPA, 'Last Verified GPA'],
  [TableColumn.LAST_VERIFIED_GPA_SCALE, 'Last Verified GPA Scale'],
  [TableColumn.LAST_VERIFIED_GPA_WEIGHT, 'Last Verified GPA Weight'],
  [TableColumn.LAST_VERIFIED_EAD_DATE, 'Date of Last Verified EAD'],
  [TableColumn.LAST_VERIFIED_EAD_EXPIRATION_DATE, 'EAD Expiration Date of Last Verified EAD'],
  [TableColumn.LAST_VERIFIED_SAR_DATE, 'Date of Last Verified SAR'],
  [TableColumn.LAST_VERIFIED_EFC_AMOUNT, 'Last Verified EFC Amount'],
  [TableColumn.LAST_VERIFIED_FAAL_DATE, 'Date of Last Verified FAAL'],
  [TableColumn.LAST_VERIFIED_FAAL_SCHOOL, 'School of Last Verified FAAL'],
  [TableColumn.LAST_VERIFIED_TOTAL_ATTENDANCE_COST, 'Last Verified Total Cost of Attendance'],
  // [TableColumn.COMPLETED, 'Completed'],
  // [TableColumn.NUMBER_OF_MENTORS, 'Number of Mentors'],
  [TableColumn.SCHOLARSHIP_AWARDED_TOTAL, 'Total Number of Scholarships Awarded'],
  [TableColumn.SCHOLARSHIP_AWARDED_SUM, 'Sum of all Scholarships Awarded'],
  // [TableColumn.ATTENDED, 'Attended'],
  // [TableColumn.FAIRS_ATTENDED, 'Fairs Attended'],
  // [TableColumn.EVENTS_ATTENDED_NUMBER, 'Number of Events Attended'],
  // [TableColumn.SCHOLARSHIPS_AWARDED, 'Scholarships Awarded'],
  // [TableColumn.CONFERENCES_ATTENDED, 'Conferences Attended'],
  // [TableColumn.JOB_CAMPAIGNS, 'Job Campaigns'],
  // [TableColumn.DISBURSEMENT_HISTORY, 'Disbursement History'],
  [TableColumn.WORK_PHONE, 'Work Phone'],
  [TableColumn.WORK_EMAIL, 'Work Email'],
  [TableColumn.CURRENT_EMPLOYMENT_STATUS, 'Current Employment Status'],
  [TableColumn.YEARS_OF_EXPERIENCE, 'Years of Experience'],
  [TableColumn.CURRENT_EMPLOYER, 'Current Employer'],
  [TableColumn.COMPANY_INDUSTRY, 'Company Industry'],
  [TableColumn.COMPANY_SECTOR, 'Company Sector'],
  [TableColumn.COMPANY_TYPE, 'Company Type'],
  [TableColumn.YEARS_AT_COMPANY, 'Years at Company'],
  [TableColumn.SENIORITY_LEVEL, 'Seniority Level'],
  [TableColumn.JOB_TITLE, 'Job Title'],
  [TableColumn.JOB_FUNCTION, 'Job Function'],
  [TableColumn.YEARS_IN_POSITION, 'Years in Position'],
  [TableColumn.BACHELORS_DEGREE_EARNED, "Bachelor's Degree Earned"],
  [TableColumn.BACHELORS_MAJOR, "Bachelor's Major(s)"],
  [TableColumn.UNDERGRADUATE_SCHOOL, 'Undergraduate School'],
  [TableColumn.BACHELORS_GRADUATION_YEAR, "Bachelor's Year of Graduation"],
  [TableColumn.MASTERS_DEGREE_EARNED, "Master's Degree Earned"],
  [TableColumn.MASTERS_MAJOR, "Master's Major(s)"],
  [TableColumn.MASTERS_SCHOOL, "Master's School"],
  [TableColumn.MASTERS_GRADUATION_YEAR, "Master's Year of Graduation"],
  [TableColumn.DOCTORS_DEGREE_EARNED, "Doctor's Degree Earned"],
  [TableColumn.DOCTORS_MAJOR, "Doctor's Major(s)"],
  [TableColumn.DOCTORS_SCHOOL, "Doctor's Undergraduate School"],
  [TableColumn.DOCTORS_GRADUATION_YEAR, "Doctor's Year of Graduation"],
  [TableColumn.STRENGTH_LEARNER, 'Strength Life-long Learner'],
  [TableColumn.STRENGTH_BUILDING, 'Strength Building Relationships'],
  [TableColumn.STRENGTH_CAREER_PLANNING, 'Strength Career Planning'],
  [TableColumn.STRENGTH_DECISION_MAKING, 'Strength Decision Making'],
  [TableColumn.STRENGTH_DELIVERING_RESULTS, 'Strength Delivering Results'],
  [TableColumn.STRENGTH_EXPLORING, 'Strength Exploring'],
  [TableColumn.STRENGTH_GOAL_SETTING, 'Strength Goal Setting'],
  [TableColumn.STRENGTH_LEADERSHIP, 'Strength Leadership'],
  [TableColumn.STRENGTH_PERSONAL_BRAND, 'Strength Personal Brand'],
  [TableColumn.STRENGTH_PAYING_FORWARD, 'Strength Paying it Forward'],
  [TableColumn.STRENGTH_PERSONAL_FINANCE, 'Strength Personal Finance'],
  [TableColumn.STRENGTH_PERSONAL_WELLNESS, 'Strength Personal Wellness'],
  [TableColumn.STRENGTH_PREP_GRAD, 'Strength Prep Grad School'],
  [TableColumn.STRENGTH_PROFESSIONAL, 'Strength Professional Development'],
  [TableColumn.STRENGTH_SELF_ASSESSMENT, 'Strength Self-assessment'],
  [TableColumn.STRENGTH_INTERVIEWING, 'Strength Interviewing'],
  [TableColumn.STRENGTH_TIME_MANAGEMENT, 'Strength Time Management'],
  [TableColumn.STRENGTH_WRITING_RESUME, 'Strength Writing a resume'],
]);

export const CUSTOM_TABLE_LOOKUP_MAP = new Map<TableColumn, LookupType>([
  [TableColumn.GENDER, LookupType.GENDER],
  [TableColumn.SEX, LookupType.SEX],
  [TableColumn.RACE, LookupType.RACES],
  [TableColumn.IDENTITY, LookupType.IDENTITIES],
  [TableColumn.HISPANIC_HERITAGE, LookupType.HERITAGE_HISPANIC],
  [TableColumn.ASIAN_HERITAGE, LookupType.HERITAGE_ASIAN],
  [TableColumn.AMERICAN_INDIAN_TRIBE, LookupType.HERITAGE_NATIVE_TRIBES],
  [TableColumn.AFRICAN_AMERICAN_HERITAGE, LookupType.HERITAGE_AFRICAN_AMERICAN],
  [TableColumn.WHITE_HERITAGE, LookupType.HERITAGE_WHITE],
  [TableColumn.CITIZENSHIP, LookupType.CITIZENSHIP],
  [TableColumn.INCOME_LEVEL, LookupType.INCOME_LEVELS],
  [TableColumn.PARENT_FIRST, LookupType.EDUCATION_LEVELS],
  [TableColumn.PARENT_SECOND, LookupType.EDUCATION_LEVELS],
  [TableColumn.HIGH_SCHOOL_ATENDING, LookupType.HIGHSCHOOL],
  [TableColumn.CURRENT_UNIVERSITY, LookupType.UNIVERSITY],
  [TableColumn.CURRENT_ENROLLMENT_TYPE, LookupType.ENROLLMENT_TYPES],
  [TableColumn.CURRENT_ENROLLMENT_STATUS, LookupType.ENROLLMENT_STATUSES],
  [TableColumn.CURRENT_CLASS_LEVEL, LookupType.CLASS_LEVELS],
  [TableColumn.GRAD_SCHOOL_TYPE, LookupType.GRADUATE_SCHOOL_TYPES],
  [TableColumn.OTHER_GRAD_SCHOOL_TYPE, LookupType.OTHER_GRADUATE_SCHOOL_TYPES],
  [TableColumn.ENGINEERING_GRAD_SCHOOL, LookupType.ENGINEERING_GRADUATE_SCHOOL_TYPES],
  [TableColumn.GPA_WEIGHT_SCALE, LookupType.GPA_WEIGHTS],
  [TableColumn.GPA_SCALE, LookupType.GPA_SCALES],
  [TableColumn.MAJOR, LookupType.MAJOR_CLASSIFICATION],
  [TableColumn.SECOND_MAJOR, LookupType.MAJOR_CLASSIFICATION],
  [TableColumn.MINOR, LookupType.MAJOR_CLASSIFICATION],
  [TableColumn.INTERESTED_MAJORS, LookupType.IN_DEMAND_MAJORS],
  [TableColumn.CURRENT_EMPLOYMENT_STATUS, LookupType.EMPLOYMENT_STATUSES],
  [TableColumn.YEARS_OF_EXPERIENCE, LookupType.EMPLOYMENT_EXPERIENCES],
  [TableColumn.COMPANY_INDUSTRY, LookupType.INDUSTRIES],
  [TableColumn.COMPANY_SECTOR, LookupType.SECTORS],
  [TableColumn.COMPANY_TYPE, LookupType.COMPANY_TYPES],
  [TableColumn.YEARS_AT_COMPANY, LookupType.EMPLOYMENT_EXPERIENCES],
  [TableColumn.SENIORITY_LEVEL, LookupType.SENIORITIES],
  [TableColumn.JOB_FUNCTION, LookupType.JOB_FUNCTIONS],
  [TableColumn.YEARS_IN_POSITION, LookupType.EMPLOYMENT_EXPERIENCES],
  [TableColumn.BACHELORS_DEGREE_EARNED, LookupType.BACHELOR_DEGREES],
  [TableColumn.BACHELORS_MAJOR, LookupType.MAJOR_CLASSIFICATION],
  [TableColumn.UNDERGRADUATE_SCHOOL, LookupType.HIGHSCHOOL],
  [TableColumn.MASTERS_DEGREE_EARNED, LookupType.MASTER_DEGREES],
  [TableColumn.MASTERS_MAJOR, LookupType.MAJOR_CLASSIFICATION],
  [TableColumn.MASTERS_SCHOOL, LookupType.UNIVERSITY],
  [TableColumn.DOCTORS_DEGREE_EARNED, LookupType.DOCTORATE_DEGREES],
  [TableColumn.DOCTORS_MAJOR, LookupType.MAJOR_CLASSIFICATION],
  [TableColumn.DOCTORS_SCHOOL, LookupType.UNIVERSITY],
]);

export enum CustomFilters {
  ROLE = 'roles',
  SEX = 'sex',
  RACE = 'race',
  ETHNICITY = 'ethnicities',
  HERITAGE = 'heritage',
  CITIZENSHIP = 'citizenshipStatus',
  CURRENT_CLASS_LEVEL = 'currentClassLevel',
  CURRENT_UNIVERSITY = 'currentUniversityName',
  CURRENT_SCHOOLS_RATING = 'currentSchoolRating',
  CURRENT_GPA = 'currentGpa',
  GRAD_SCHOOL_TYPE = 'graduateSchoolType',
  INTERESTED_MAJOR = 'otherMajorsConsidered',
  MAJOR = 'primaryMajor',
  MAJOR_CLASSIFICATION_GROUPS = 'majorClassificationGroups',
  SECOND_MAJOR = 'secondaryMajor',
  MINOR = 'minor',
  CURRENT_ENROLLMENT_TYPE = 'currentEnrollmentType',
  CURRENT_ENROLLMENT_STATUS = 'currentEnrollmentStatus',
  CURRENT_ADDRESS_STATE = 'currentAddressState',
  HOMETOWN_STATE = 'hometownState',
  STAGE_FORM_STATUS = 'stageFormStatus',
}

export const CUSTOM_FILTERS_LABELS = new Map<CustomFilters, string>([
  [CustomFilters.ROLE, 'Role'],
  [CustomFilters.SEX, 'Sex'],
  [CustomFilters.RACE, 'Race'],
  [CustomFilters.ETHNICITY, 'Ethnicity'],
  [CustomFilters.HERITAGE, 'Heritage'],
  [CustomFilters.CITIZENSHIP, 'Citizenship'],
  [CustomFilters.CURRENT_CLASS_LEVEL, 'Current class level'],
  [CustomFilters.CURRENT_UNIVERSITY, 'Current  university'],
  [CustomFilters.CURRENT_SCHOOLS_RATING, 'Current schools rating'],
  [CustomFilters.CURRENT_GPA, 'Current GPA'],
  [CustomFilters.GRAD_SCHOOL_TYPE, 'Grad school type'],
  [CustomFilters.INTERESTED_MAJOR, 'Interested Major'],
  [CustomFilters.MAJOR, 'Major'],
  [CustomFilters.MAJOR_CLASSIFICATION_GROUPS, 'Major classification groups'],
  [CustomFilters.SECOND_MAJOR, 'Second Major'],
  [CustomFilters.MINOR, 'Minor'],
  [CustomFilters.CURRENT_ENROLLMENT_TYPE, 'Current Enrollment Type'],
  [CustomFilters.CURRENT_ENROLLMENT_STATUS, 'Current Enrollment Status'],
  [CustomFilters.CURRENT_ADDRESS_STATE, 'Current address state'],
  [CustomFilters.HOMETOWN_STATE, 'Hometown state'],
  [CustomFilters.STAGE_FORM_STATUS, 'Stage Form Status'],
]);

export const DateFormatFields = [
  TableColumn.DOB,
  TableColumn.LAST_VERIFIED_EV_DATE,
  TableColumn.LAST_VERIFIED_TRANSCRIPT_DATE,
  TableColumn.LAST_VERIFIED_EAD_DATE,
  TableColumn.LAST_VERIFIED_EAD_EXPIRATION_DATE,
  TableColumn.LAST_VERIFIED_SAR_DATE,
  TableColumn.LAST_SCHOLARSHIP_APPLICATION_DATE,
  TableColumn.LAST_VERIFIED_FAAL_DATE,
  TableColumn.EXPECTED_GRADUATION_DATE,
];

export const DefaultFieldsConfig = [
  TableColumn.FIRST_NAME,
  TableColumn.MIDDLE_NAME,
  TableColumn.LAST_NAME,
  TableColumn.TENANT_USER_ID,
  TableColumn.EMAIL,
  TableColumn.MOBILE_PHONE,
  TableColumn.ROLE,
];

export const ScholarFieldsConfig = [
  TableColumn.FIRST_NAME,
  TableColumn.MIDDLE_NAME,
  TableColumn.LAST_NAME,
  TableColumn.TENANT_USER_ID,
  TableColumn.EMAIL,
  TableColumn.MOBILE_PHONE,
  TableColumn.SEX,
  TableColumn.CURRENT_CLASS_LEVEL,
  TableColumn.EXPECTED_GRADUATION_DATE,
  TableColumn.CURRENT_UNIVERSITY,
  TableColumn.DECLARED_MAJOR,
  TableColumn.MAJOR,
  TableColumn.SECOND_MAJOR,
  TableColumn.MINOR,
  TableColumn.CURRENT_ENROLLMENT_TYPE,
  TableColumn.CURRENT_ENROLLMENT_STATUS,
  TableColumn.LAST_VERIFIED_EV_DATE,
  TableColumn.CURRENT_ENROLLMENT_STATUS,
  TableColumn.CURRENT_ENROLLMENT_STATUS,
  TableColumn.DATE_OF_LAST_UPDATE_OF_CURRENT_SCHOOL,
];

export const ReaderViewFieldsConfig = [...DefaultFieldsConfig, TableColumn.CERTIFICATIONS];

export const heritages = [
  TableColumn.AFRICAN_AMERICAN_HERITAGE,
  TableColumn.ASIAN_HERITAGE,
  TableColumn.HISPANIC_HERITAGE,
  TableColumn.AMERICAN_INDIAN_TRIBE,
  TableColumn.WHITE_HERITAGE,
];

export type Column = {
  dataIndex: string;
  key: TableColumn;
  title: string;
  width: number;
};

export interface AddFlagFormData {
  reasonId?: string;
  programName?: string;
  description?: string;
}

export interface IDonorCompany {
  id: string;
  company: string;
}

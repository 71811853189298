import React from 'react';

import GoogleTranslateComponent from './google-translate';
import CMSTranslate from './cms-translate';
import i18n from './utils';

const LanguageSelector = () => {
  return (
    <>
      {i18n.isProviderGoogle && (
        <GoogleTranslateComponent defaultLanguage={i18n.defaultLanguage} languages={i18n.languages} />
      )}
      {i18n.isProviderCMS && <CMSTranslate defaultLanguage={i18n.defaultLanguage} languages={i18n.languages} />}
    </>
  );
};
export default LanguageSelector;

import { navigate } from 'gatsby';

import { AppThunk } from './index';

import { ILoginFormData, ISignUpFormData } from '../../types/models/auth';
import { login, saveMobileAuthToken, signUp, ssoLogin } from '../../apis/auth';
import { CustomError } from '../../types/models/error';
import { SIGN_UP_CONFIRMATION_URL, SYSTEM_UPGRADE_PASSWORD_RESET_URL } from '../../constants';

export enum AuthActionTypes {
  LOGIN = 'LOGIN',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',
  SSO_LOGIN_SUCCESS = 'SSO_LOGIN_SUCCESS',
  LOGOUT = 'LOGOUT',
  SAVE_MOBILE_TOKEN_SUCCESS = 'SAVE_MOBILE_TOKEN_SUCCESS',
  SIGN_UP = 'SIGN_UP',
  SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE = 'SIGN_UP_FAILURE',
  RESET_ERROR = 'RESET_ERROR',
}

export interface LoginAction {
  type: typeof AuthActionTypes.LOGIN;
}

export interface LoginSuccessAction {
  type: typeof AuthActionTypes.LOGIN_SUCCESS;
  payload: { token: string };
}

export interface SSOLoginSuccessAction {
  type: typeof AuthActionTypes.SSO_LOGIN_SUCCESS;
  payload: {
    entityEndpoint: string;
    context: string;
  };
}

export interface LoginFailureAction {
  type: typeof AuthActionTypes.LOGIN_FAILURE;
  error: CustomError;
}

export interface LogoutAction {
  type: typeof AuthActionTypes.LOGOUT;
}

export interface SaveMobileTokenSuccessAction {
  type: typeof AuthActionTypes.SAVE_MOBILE_TOKEN_SUCCESS;
  payload: { token: string };
}

export interface SignUpAction {
  type: typeof AuthActionTypes.SIGN_UP;
}

export interface SignUpSuccessAction {
  type: typeof AuthActionTypes.SIGN_UP_SUCCESS;
}

export interface SignUpFailureAction {
  type: typeof AuthActionTypes.SIGN_UP_FAILURE;
  error: Error;
}

export interface ResetErrorAction {
  type: typeof AuthActionTypes.RESET_ERROR;
}

export function loginAction({ email = '', password = '', rememberMe = false }: Partial<ILoginFormData>): AppThunk {
  return async (dispatch) => {
    dispatch({ type: AuthActionTypes.LOGIN });

    try {
      const response = await login(email, password, rememberMe);
      dispatch({ type: AuthActionTypes.LOGIN_SUCCESS, payload: response });
      navigate('/myhsf/dashboard');
    } catch (error) {
      if (error.status === 301) {
        navigate(SYSTEM_UPGRADE_PASSWORD_RESET_URL, { state: { email: email } });
      } else {
        dispatch({ type: AuthActionTypes.LOGIN_FAILURE, error });
      }
    }
  };
}

export function signUpAction({
  firstName,
  lastName,
  email,
  password,
  dob,
  role,
  tos,
  currentClassLevel,
}: ISignUpFormData): AppThunk {
  return async (dispatch) => {
    dispatch({ type: AuthActionTypes.SIGN_UP });

    try {
      const response = await signUp(firstName, lastName, email, password, dob, tos, role, currentClassLevel);

      dispatch({ type: AuthActionTypes.SIGN_UP_SUCCESS });
      /*not setting user id in redux,  instead passing it as a param in navigate
       as its only needed in sign-up confirmation page for now*/
      navigate(SIGN_UP_CONFIRMATION_URL, { state: { userId: response.userId } });
    } catch (error) {
      dispatch({ type: AuthActionTypes.SIGN_UP_FAILURE, error });
    }
  };
}

export function logoutAction() {
  return {
    type: AuthActionTypes.LOGOUT,
  };
}

//TODO: Logic for calling verification email and navigate
export function sendEmailVerification() {
  navigate(SIGN_UP_CONFIRMATION_URL);
}

export function resetErrorAction(path: string) {
  navigate(path);
  return {
    type: AuthActionTypes.RESET_ERROR,
  };
}

export function saveMobileToken(token: string): AppThunk {
  return async (dispatch) => {
    try {
      await saveMobileAuthToken(token);
      dispatch({ type: AuthActionTypes.SAVE_MOBILE_TOKEN_SUCCESS, payload: { token } });
    } catch (error) {
      console.error(error);
    } finally {
      navigate('/myhsf/dashboard');
    }
  };
}

export function ssoLoginAction({
  samlRequest,
  email,
  password,
  rememberMe,
}: { samlRequest: string } & Partial<ILoginFormData>): AppThunk {
  return async (dispatch) => {
    dispatch({ type: AuthActionTypes.LOGIN });

    try {
      const response = await ssoLogin(samlRequest, email, password, rememberMe);
      dispatch({ type: AuthActionTypes.LOGIN_SUCCESS, payload: { token: response.token } });
      dispatch({ type: AuthActionTypes.SSO_LOGIN_SUCCESS, payload: { ...response.samlResponse } });
    } catch (error) {
      dispatch({ type: AuthActionTypes.LOGIN_FAILURE, error });
    }
  };
}

export type AuthAction =
  | LoginAction
  | LoginSuccessAction
  | LoginFailureAction
  | SSOLoginSuccessAction
  | LogoutAction
  | SignUpAction
  | SaveMobileTokenSuccessAction
  | SignUpFailureAction
  | SignUpSuccessAction
  | ResetErrorAction;

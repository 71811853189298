import { API_URL, FP_API_URL, TENANT_ID } from './constants';

import EventCalendarContextProvider from './containers/event-calendar/context';
import { I18NContextProvider } from './i18n';
import ModalContextProvider from './components/home/ModalProvider';
import { Provider } from 'react-redux';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import axios from 'axios';
import createStore from './state/store/createStore';
import { getToken } from './state/selectors/auth';

// Global query configuration, can be overwritten by each query option
// const queryConfig = {
//   refetchAllOnWindowFocus: false, // disable default window focus re-fetch
//   staleTime: Infinity, // data will never stale
//   cacheTime: 0, // no cache
// };

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const store = createStore();

// define global axios interceptors to set `Authorization` header and current tenant
axios.interceptors.request.use(function (config) {
  const { url = '' } = config;
  // only add `Authorization` header for prosper request
  const shouldIncludeAuthorization = url.search(API_URL) > -1 || (FP_API_URL && url.search(FP_API_URL) > -1);

  // only add `tenant` header for prosper request
  const shouldIncludeTenant = url.search(API_URL) > -1 || (FP_API_URL && url.search(FP_API_URL) > -1);
  const token = getToken(store.getState());

  if (shouldIncludeAuthorization && token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  if (shouldIncludeTenant) {
    config.headers.tenant = TENANT_ID;
  }

  return config;
});
// root component for all the gatsby pages
export default function RootWrapper({ element }: { element: React.ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <I18NContextProvider>
          <EventCalendarContextProvider>
            <ModalContextProvider>{element}</ModalContextProvider>
          </EventCalendarContextProvider>
        </I18NContextProvider>
      </Provider>
    </QueryClientProvider>
  );
}

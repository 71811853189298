import React, { createContext, useState, useContext } from 'react';

import i18n from './utils';

type I18NContext = {
  language: string;
  setLanguage: (language: string) => void;
  translate: (entity: any) => any;
  translateSlug: (slug: string) => string;
};

const initial: I18NContext = {
  language: i18n.defaultLanguage,
  setLanguage: () => undefined,
  translate: () => undefined,
  translateSlug: () => '',
};

const I18NContext = createContext<I18NContext>(initial);

const LocalStorageLanguageItem = 'language';

const I18NContextProvider: React.FC<{}> = ({ children }) => {
  const location = typeof window !== `undefined` ? window?.location : null;
  const [language, setLanguage] = useState(
    (location && localStorage.getItem(LocalStorageLanguageItem)) ||
      i18n.languages.find((lang: string) => location?.pathname.includes(`/${lang}/`)) ||
      i18n.defaultLanguage
  );

  const translate = (entity: any) => {
    return i18n.translateEntity(entity, language);
  };

  const translateSlug = (slug: string) => {
    return i18n.translateSlug(slug, language);
  };

  const _setLanguage = (language: string) => {
    setLanguage(language);
    location && localStorage.setItem(LocalStorageLanguageItem, language);
  };

  return (
    <I18NContext.Provider
      value={{
        language,
        setLanguage: _setLanguage,
        translate,
        translateSlug,
      }}
    >
      {children}
    </I18NContext.Provider>
  );
};

export default I18NContextProvider;

export const useI18NContext = () => {
  return useContext(I18NContext);
};

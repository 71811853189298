import axios from 'axios';

import { IUserProfile, OptionRecord } from '../../types/models/user-management';
import { API_URL } from '../../constants';
import { createError } from '../error';
import responseParser from '../response-parser';

function transformUserProfile(profile: IUserProfile): IUserProfile {
  if (profile.major && profile.profilePayload) {
    return {
      ...profile,
      profilePayload: {
        ...profile.profilePayload,
        data: {
          ...profile.profilePayload.data,
          primary_major: profile.major, //eslint-disable-line
          have_declared_major: 'Yes', // eslint-disable-line
        },
      },
    };
  }
  return profile;
}

/**
 * Get Profile with the user Id
 * @param userId
 * @param expand
 */
export async function getProfile(userId?: string, expand?: boolean): Promise<IUserProfile> {
  if (!userId) {
    return {} as IUserProfile;
  }
  const url = `${API_URL}/users/${userId}/profile`;
  try {
    const response = await axios.get(url, { params: { expand } });
    const data: IUserProfile = responseParser(response);
    return transformUserProfile(data);
  } catch (err) {
    throw createError(err);
  }
}
getProfile.QUERY_KEY = 'getProfile';

/**
 * Edit Profile with all the payload data
 * @param id
 * @param profile
 */
export async function editProfile(userId: string, profile: Partial<IUserProfile>): Promise<IUserProfile> {
  if (!userId) {
    return {} as IUserProfile;
  }
  const url = `${API_URL}/users/${userId}/profile`;
  try {
    const response = await axios.put(url, profile);
    const data: IUserProfile = responseParser(response);
    return data;
  } catch (err) {
    throw createError(err);
  }
}

export async function patchProfile(userId: string, profile: Partial<IUserProfile>): Promise<IUserProfile> {
  if (!userId) {
    return {} as IUserProfile;
  }
  const url = `${API_URL}/users/${userId}/profile`;
  try {
    const response = await axios.patch(url, profile);
    const data: IUserProfile = responseParser(response);
    return data;
  } catch (err) {
    throw createError(err);
  }
}

/**
 * The solution to get the lookup tables is the temporary solution, need more discussion
 */
export async function getLookupList(): Promise<OptionRecord> {
  const url = `${API_URL}/users/profile-lookup-data`;
  try {
    const response = await axios.get(url);
    const data: OptionRecord = responseParser(response);
    return data;
  } catch (err) {
    throw createError(err);
  }
}

export async function getClassLevelsList(): Promise<OptionRecord> {
  const url = `${API_URL}/class-levels`;
  try {
    const response = await axios.get(url);
    return responseParser(response);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      throw createError(err);
    }

    throw createError(err);
  }
}

export async function getEnrollmentStatusesList(): Promise<OptionRecord> {
  const url = `${API_URL}/catalog-api?name=enrollment-statuses`;
  try {
    const response = await axios.get(url);
    return responseParser(response);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      throw createError(err);
    }

    throw createError(err);
  }
}

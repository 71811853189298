import {
  MentorsFilterAction,
  MentorsFilterTypes,
  MentorsFilterState,
} from '../../../../../../types/myhsf/mentorship/manual-application/mentors-list/mentors-filter-types';

const initialState: MentorsFilterState = {
  jobTypeValue: '',
  collegeMajorArea: '',
  skills: [],
  location: '',
  sex: '',
  education: '',
  professionalExperience: '',
  visibleAdvancedFilters: false,
};

export default function manualMentorsMatchFilterState(
  state = initialState,
  action: MentorsFilterAction
): MentorsFilterState {
  switch (action.type) {
    case MentorsFilterTypes.setJobTypeValue: {
      return {
        ...state,
        jobTypeValue: action.payload,
      };
    }
    case MentorsFilterTypes.setCollegeMajorAreaValue: {
      return {
        ...state,
        collegeMajorArea: action.payload,
      };
    }
    case MentorsFilterTypes.setSkillsValue: {
      return {
        ...state,
        skills: action.payload,
      };
    }
    case MentorsFilterTypes.setSexValue: {
      return {
        ...state,
        sex: action.payload,
      };
    }
    case MentorsFilterTypes.setProfessionalExperienceValue: {
      return {
        ...state,
        professionalExperience: action.payload,
      };
    }
    case MentorsFilterTypes.setEducationValue: {
      return {
        ...state,
        education: action.payload,
      };
    }
    case MentorsFilterTypes.setLocationValue: {
      return {
        ...state,
        location: action.payload,
      };
    }
    case MentorsFilterTypes.setVisibleAdvancedFilters: {
      return {
        ...state,
        visibleAdvancedFilters: action.payload,
      };
    }
    default:
      return state;
  }
}

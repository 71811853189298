import { IMasterTableData } from './models/master-table';
import { DocumentType } from './models/document';
import { LookupType } from './models/lookup';

import { Operator } from '../utils/matchers/ICondition';
import { OptionProps } from '../components/forms';

export type Optional<T> = T | undefined;

export type SelectOption = OptionProps;

//-------------------------------------------
// Form Type
//-------------------------------------------

export enum FormType {
  APPLICATION = 'application',
  QUIZ = 'quiz',
}

//-------------------------------------------
// Form Component Type
//-------------------------------------------

export enum FormComponentType {
  Panel = 'panel',
  Label = 'label',
  Text = 'text',
  Textarea = 'textarea',
  Dropdown = 'dropdown',
  Checkbox = 'checkbox',
  Checkboxgroup = 'checkboxgroup',
  Number = 'number',
  Radiogroup = 'radiogroup',
  Date = 'date',
  Address = 'address',
  ProfileInfo = 'profileInfo',
  RecommenderInfo = 'recommenderInfo',
  CounselorInfo = 'counselorInfo',
  PhoneNumber = 'phone',
  Grade = 'grade',
  RichTextEditor = 'rte',
  DateTime = 'datetime',
  AutoComplete = 'autocomplete',
  LinkToPage = 'linkToPage',
  Location = 'location',
  SSN = 'ssn',
  MultiSelectPhotos = 'multiSelectPhotos',
  FileUpload = 'fileUpload',
  DocumentUploadPanel = 'documentUploadPanel',
  DocumentTypeMetadataTitle = 'documentTypeMetadataTitle',
  DocumentUpload = 'documentUpload',
  ReaderQuiz = 'readerQuiz',
  CoachQuiz = 'coachQuiz',
  InterviewerQuiz = 'interviewerQuiz',
  MentorQuiz = 'mentorQuiz',
  Hidden = 'hidden',
  DragNDropOption = 'dragNDropOption',
  DragNDropOptionPanel = 'dragNDropOptionPanel',
  FERPAStatement = 'ferpa',
  FinancialPortal = 'financialPortal',
}

export enum FormPanelComponentType {
  Panel = 'panel',
  Address = 'address',
  ProfileInfo = 'profileInfo',
  RecommenderInfo = 'recommenderInfo',
  DocumentUploadPanel = 'documentUploadPanel',
  CounselorInfo = 'counselorInfo',
  DateTime = 'datetime',
  Location = 'location',
  ReaderQuiz = 'readerQuiz',
  CoachQuiz = 'coachQuiz',
  InterviewerQuiz = 'interviewerQuiz',
  MentorQuiz = 'mentorQuiz',
  DragNDropOptionPanel = 'dragNDropOptionPanel',
  FERPAStatement = 'ferpa',
  FinancialPortal = 'financialPortal',
}

// value used as a label in form builder `Value Settings`
export enum NumberType {
  Integer = 'integer',
  Decimal = 'decimal',
}

export enum GradeType {
  Letter = 'letter',
  Number = 'number',
}

export type InputType = 'input' | 'password';

export type ValueType = NumberType | GradeType;

export interface IValidatableSchema {
  elements?: IValidatableSchema[]; // panel only
  name: string;
  title?: string;
  validation?: IValidationSchema;
  repeatable?: boolean;
  type?: FormComponentType;
}

export interface OrCondition {
  or?: IVisibleSchema[];
}

export interface IElementSchema {
  id: string;
  type: FormComponentType;
  inputType?: InputType; // input only
  valueType?: ValueType;
  elements?: IElementSchema[]; // panel only
  repeatable?: boolean; // panel only
  maxRepeatable?: number; // panel only
  isOrderedList?: boolean; // repeatable panel only
  name: string;
  title?: string;
  placeHolder?: string; // input, select only
  options?: IOption[]; // select, checkboxgroup, radiogroup, autocomplete, dragNdropOptionPanel only
  dataSource?: IElementDataSource; // select, checkboxgroup, radiogroup, autocomplete only
  maxLength?: number; // input only
  hasCheckAll?: boolean; // checkboxgroup only
  hasOtherOption?: boolean; // radiogroup only
  disable?: any[] | boolean; // set to true to force disable a field
  visible?: IVisibleSchema;
  allowNegativeValue?: boolean;
  validation?: IValidationSchema;
  tooltip?: string;
  instructiveLabel?: string;
  canSaveToProfile?: boolean; // profileInfo only
  canPopulateFromProfile?: boolean; // profileInfo only
  showTime?: boolean; // date picker only
  url?: string; // linkToPage only
  linkLabel?: string; // linkToPage only
  encrypted?: boolean; // SSN only
  titlePath?: string;
  maxFileSize?: number; // file upload only
  maxUploadLimit?: number; // file upload only
  documentType?: DocumentType; // file upload only
  pullFromDocBank?: boolean; //file upload only
  currencySymbol?: string;
  validationRequired?: boolean; //only Upload panel
  isEvaluationRequired?: boolean; //only Upload panel
  answer?: any; // quiz only
  selectionQualifier?: boolean;
  populateFromProfile?: boolean;
  syncToProfile?: boolean;
  profileSyncField?: string;
  preventSearchStringSave?: boolean; // only autocomplete
  decimalPlaces?: number;
  hiddenValue?: string; // only hidden
  hiddenFieldType?: string; // only hidden
  isDeselectable?: boolean; // only radio group
}

export interface IValidationSchema {
  type?: string; // keyof yup.LocaleObject; <- this doesn't work..
  rules: IValidationRuleSchema[];
}

// TODO: support nested structure once we need it
export interface IVisibleSchema {
  fieldName?: string;
  operator?: Operator;
  value?: any;
  label?: string | string[];
  lookup?: LookupType;
  or?: OrCondition[]; //for nested conditions
}

export enum ValidationRuleType {
  REQUIRED = 'required',
  DEFAULT = 'default',
  // TODO Needs to implement
  MIN = 'min',
  MAX = 'max',
  EMAIL = 'email',
  YEAR = 'year',
  INTEGER = 'integer',
  POSITIVE = 'positive',
  NULLABLE = 'nullable',
  RTE_REQUIRED = 'rteRequired',
  RTE_MAX_WORD_COUNT = 'rteMaxWordCount',
  RTE_MIN_WORD_COUNT = 'rteMinWordCount',
}

export interface IValidationRuleSchema {
  type: ValidationRuleType;
  params?: any[];
}

export interface IPageSchema {
  type: string;
  title?: string;
  elements: IElementSchema[];
}

export interface ISectionSchema {
  type: string;
  title?: string;
  pages: IPageSchema[];
}

export interface IFormSchema {
  sections: ISectionSchema[];
}

export interface IFormDataSchema {
  data: IFormData;
  encryptFields?: string[];
}

export interface IFormData {
  [key: string]: any;
}

export type IFormPayload = IFormDataSchema;

export interface IOption {
  label: string;
  value: number | boolean | string;
}

export interface IElementDataSource {
  url: string;
  valueName: string;
  titleName: string;
  sourceField?: string;
  lookup?: LookupType;
  transformTitle?: (value: string, data?: IMasterTableData) => string;
}

export interface IMemo {
  shouldValidate?: boolean;
}

export interface ICorrectionComment {
  key: string;
  comment: string;
}

//-------------------------------------------
// Form Errors
//-------------------------------------------

export interface ISectionFormError {
  pages: FieldErrors<Record<string, any>>[];
}
export interface IFormErrors {
  sections: ISectionFormError[];
}

//-------------------------------------------
// Form Status
//-------------------------------------------

// TODO: finalize the form status
export enum FormStatus {
  Completed = 'completed',
  NotStarted = 'notStarted',
  InProgress = 'inProgress',
  NeedsCorrection = 'needsCorrection',
  Declined = 'declined',
  Flagged = 'flagged',
  Pending = 'pending',
  Resubmitted = 'resubmitted',
}

export enum RequestStatus {
  PENDING = 'requested',
  ACCEPTED = 'accepted',
  DECLINED = 'rejected',
}

//TODO: this is for both volunteer application (accepted/declined) and certification (certified/expired/not_certified), should break this out one tday
export enum CertificateStatus {
  Accepted = 'accepted',
  Declined = 'declined',
  Certified = 'certified',
  NotCertified = 'notCertified',
  Expired = 'expired',
  NotAvailable = 'notAvailable',
  PendingReview = 'pendingReview',
  Revoked = 'revoked',
}

export enum BackgroundCheckStatus {
  Passed = 'passed',
  NotPassed = 'notPassed',
  NotAvailable = 'notAvailable',
}

export enum HousingStatusType {
  OnCampus = 'onCampus',
  OffCampus = 'offCampus',
  LivingWithParentsOrRelatives = 'livingWithParentsOrRelatives',
}
//-------------------------------------------
// Quiz Form Status
//-------------------------------------------

// matching the name with BE
export enum QuizStatus {
  PASSED = 'passed',
  NOT_PASSED = 'notPassed',
  NOT_STARTED = 'notStarted',
  NOT_AVAILABLE = 'notAvailable',
}

export interface IFormDictionary {
  roleName: string;
  packetUrl?: string;
  videoUrl?: string;
  nda: {
    forms: string[];
    agreements?: string[];
    title: string;
    instructiveLabel: string;
    ndaListTitle: string;
    ndaCheckboxTitle: string;
  };
}

export enum RecommenderType {
  Clergy = 'clergy',
  Coach = 'coach',
  Colleague = 'colleague',
  Counselor = 'counselor',
  ElectedOfficial = 'electedOfficial',
  Manager = 'manager',
  Mentor = 'mentor',
  Principal = 'principal',
  Professor = 'professor',
  ResearchThesisAdvisor = 'researchThesisAdvisor',
  SchoolAdministrator = 'schoolAdministrator',
  Supervisor = 'supervisor',
  Teacher = 'teacher',
  Tutor = 'tutor',
}

export enum EnrollmentVerificationType {
  HSFForm = 'HSFForm',
  NationalStudentClearingHouse = 'nationalStudentClearingHouse',
  InstitutionalVerificationForm = 'institutionalVerificationForm',
}

export enum CurrentEnrollmentStatusType {
  FullTime = 'fullTime',
  ThreeQuarterTime = 'threeQuarterTime',
  HalfTime = 'halfTime',
  LessThanHalfTime = 'lessThanHalfTime',
  Enrolled = 'enrolled',
  LeaveOfAbsence = 'leaveOfAbsence',
  Withdrawn = 'withdrawn',
  Deceased = 'deceased',
}

export enum GPAScaleType {
  Four = '4.0',
  Five = '5.0',
  Six = '6.0',
  Seven = '7.0',
  Ten = '10.0',
  Hundread = '100.0',
  Written = 'Written Evaluation',
  Other = 'Other Scale',
}

export enum GPAWeightType {
  Weighted = 'Weighted',
  Unweighted = 'Unweighted',
  NotApplicable = 'Not Applicable',
}

export interface ICurrentPosition {
  sectionIndex: number;
  pageIndex: number;
}
export interface IPageState {
  validated: boolean;
  showError: boolean;
}

export interface ISectionState {
  validated: boolean;
  expanded: boolean;
  pages: IPageState[];
}
export interface IFormState {
  currentPosition: ICurrentPosition;
  sections: ISectionState[];
}
export interface IStatusExplanations {
  acceptExplanation?: string;
  declineExplanation?: string;
}

export interface IFormMemo {
  formStatus?: FormStatus;
  nda?: boolean | null;
  quizCount?: number;
  quizStatus?: QuizStatus;
}

//-------------------------------------------
// Hidden Fields
//-------------------------------------------

export interface IHiddenFields {
  [key: string]: boolean;
}

export enum HiddenFieldType {
  DEFAULT = 'default',
  EVENT_ROLE = 'eventRole',
  USER_ROLE = 'userRole',
}

//-------------------------------------------
// Form Builder
//-------------------------------------------

export interface IComponentSettings {
  name: string;
  title?: string;
  options?: IOption[];
  dataSource?: IElementDataSource;
  visible?: IVisibleSchema;
  allowNegativeValue?: boolean;
  repeatable?: boolean;
  isOrderedList?: boolean;
  valueType?: ValueType;
  hasCheckAll?: boolean;
  hasOtherOption?: boolean;
  validation?: IValidationSchema;
  tooltip?: string;
  isMultilineText?: boolean;
  instructiveLabel?: string;
  valueSettingOptions?: IOption[];
  canSaveToProfile?: boolean;
  canPopulateFromProfile?: boolean;
  linkLabel?: string;
  url?: string;
  maxRepeatable?: number;
  documentType?: DocumentType;
  validationRequired?: boolean;
  isEvaluationRequired?: boolean;
  selectionQualifier?: boolean;
  syncToProfile?: boolean;
  populateFromProfile?: boolean;
  profileSyncField?: string;
  decimalPlaces?: number;
  hiddenValue?: string;
  hiddenFieldType?: string;
  preventSearchStringSave?: boolean;
}

//-------------------------------------------
// Yup Validation
//-------------------------------------------
// Borrowed from https://github.com/react-hook-form/react-hook-form/blob/master/src/types.ts
type Ref = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | any;
export type FieldValues = Record<string, any>;
export type ValidateResult = string | boolean | undefined;
export type MultipleFieldErrors = Record<string, ValidateResult>;

export interface FieldError {
  type: string;
  ref?: Ref;
  types?: MultipleFieldErrors;
  message?: string;
  isManual?: boolean;
}

export type NestDataObject<FormValues> = {
  [Key in keyof FormValues]?: FormValues[Key] extends Array<infer U>
    ? 0 extends 1 & U
      ? any
      : unknown extends U
      ? FieldError[]
      : object extends U
      ? FieldError[]
      : U extends Date
      ? FieldError[]
      : U extends object
      ? FieldErrors<U>[]
      : FieldError[]
    : 0 extends 1 & FormValues[Key]
    ? any
    : unknown extends FormValues[Key]
    ? FieldError
    : object extends FormValues[Key]
    ? FieldError
    : FormValues[Key] extends Date
    ? FieldError
    : FormValues[Key] extends object
    ? FieldErrors<FormValues[Key]>
    : FieldError;
};

export type SchemaValidateOptions = Partial<{
  strict: boolean;
  abortEarly: boolean;
  stripUnknown: boolean;
  recursive: boolean;
  context: object;
}>;

export type FieldErrors<FormValues> = NestDataObject<FormValues>;

export interface SchemaValidationResult<FormValues> {
  errors: FieldErrors<FormValues>;
  values: FieldValues;
}
export interface YupValidationError {
  inner: { path: string; message: string; type: string }[];
  path: string;
  message: string;
  type: string;
}

export interface Schema<Data> {
  validate(value: FieldValues, options?: SchemaValidateOptions): Promise<Data>;
}

export type PathOption = {
  labelPath: string;
  valuePath: string;
};

export type TreeNodeOption = {
  title: string;
  value?: string;
  key?: string;
  children?: TreeNodeOption[];
  selectable: boolean;
  disabled: boolean;
};

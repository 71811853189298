import axios from 'axios';

import { CustomAxiosError } from '../../types/models/error';
import { API_URL } from '../../constants';
import { createError } from '../error';
import responseParser from '../response-parser';
import { ICohortManagementGetRequestParams } from '../../types/dto/cohort-management';

export const getCohortList = async (): Promise<any> => {
  const url = `${API_URL}/cohorts`;
  try {
    const response = await axios.get(url);
    return responseParser(response);
  } catch (e: any) {
    throw createError(e as CustomAxiosError);
  }
};
getCohortList.QUERY_KEY = 'getCohortList';

export const getScholarsList = async (query: object[], offset: number, limit: number): Promise<any> => {
  const url = `${API_URL}/cohorts/dashboard`;

  const config = {
    params: {
      offset,
      limit,
      filter: JSON.stringify(query),
    },
  };

  try {
    const response = await axios.get(url, config);
    return { data: responseParser(response), totalCount: response.data.totalCount };
  } catch (e: any) {
    throw createError(e as CustomAxiosError);
  }
};
getScholarsList.QUERY_KEY = 'getScholarsList';

export const getInternalCohorts = async (params: ICohortManagementGetRequestParams): Promise<any> => {
  const url = `${API_URL}/internal/cohorts`;
  try {
    const response = await axios.get(url, { params });
    return { data: responseParser(response), totalCount: response.data.totalCount };
  } catch (e: any) {
    throw createError(e as CustomAxiosError);
  }
};
getInternalCohorts.QUERY_KEY = 'getInternalCohorts';

export const createCohort = async (payload: { cohortName: string; cohortYear: number }): Promise<any> => {
  const url = `${API_URL}/admin/cohorts`;
  try {
    await axios.post(url, payload);
  } catch (e: any) {
    throw createError(e);
  }
};
createCohort.QUERY_KEY = 'createCohort';

export const deleteCohort = async (cohortId: string): Promise<any> => {
  const url = `${API_URL}/admin/cohorts/${cohortId}`;
  try {
    await axios.delete(url);
  } catch (e: any) {
    throw createError(e);
  }
};
deleteCohort.QUERY_KEY = 'deleteCohort';

import { MessageAction, MessageActionTypes } from '../actions/message';

const initialState = {
  userId: '',
  isLoadingChat: false,
};

const chat = (state = initialState, action: MessageAction) => {
  switch (action.type) {
    case MessageActionTypes.OPEN_CHAT:
      return {
        ...state,
        userId: action.payload,
      };
    case MessageActionTypes.CLEAR:
      return {
        ...state,
        userId: '',
      };
    case MessageActionTypes.SET_IS_LOADING_CHAT:
      return {
        ...state,
        isLoadingChat: action.payload,
      };
    default:
      return state;
  }
};

export default chat;

import React, { useState, useContext } from 'react';
// import styled from '@emotion/styled';

import Modal from '../../../components/modal';
import { DEFAULT_MODAL_WIDTH } from '../../../constants';

// const TitleBlock = styled.div`
//   font-size: 24px;
//   font-weight: bold;
//   line-height: 1.33;
//   letter-spacing: -0.1px;
// `;

interface IModalConfig {
  content?: JSX.Element;
  width?: number;
  title?: string;
  footer?: JSX.Element;
  onClose?: (params?: any) => void;
}

interface IContextValues {
  displayModal: (config: IModalConfig) => void;
}

// const renderModalTitle = (title: string) => {
//   return <TitleBlock>{title}</TitleBlock>;
// };

const ModalContext = React.createContext<IContextValues>({ displayModal: () => undefined });

const ModalContextProvider: React.FC<{}> = ({ children }) => {
  const [modals, setModals] = useState<IModalConfig[]>([]);

  const displayModal = (modalConfig: IModalConfig) => {
    setModals(currentState => [...currentState, modalConfig]);
  };

  const getCancellationMethod = (index: number, item: IModalConfig) => () => {
    setModals(currentState => currentState.filter((_, idx) => idx !== index));
    if (item.onClose) {
      item.onClose();
    }
  };

  return (
    <ModalContext.Provider value={{ displayModal }}>
      {modals.map((i, idx) => {
        const { content, width, title } = i;
        return (
          <Modal
            key={idx}
            width={width || DEFAULT_MODAL_WIDTH}
            title={title}
            visible={true}
            centered
            footer={null}
            onCancel={getCancellationMethod(idx, i)}
          >
            <div>{content}</div>
          </Modal>
        );
      })}
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = (): IContextValues => {
  const { displayModal } = useContext(ModalContext);
  return { displayModal };
};

export default ModalContextProvider;

import React from 'react';
import { TooltipProps as $TooltipProps } from 'antd5/lib/tooltip';
import HelpRounded from '@material-ui/icons/HelpRounded';
import styled from '@emotion/styled';

import { Tooltip as $Tooltip } from '../components/common/antd/antd-override';

export type TooltipProps = $TooltipProps & {
  children?: React.ReactNode;
};

const Container = styled.span`
  display: table;
`;

const Content = styled.span`
  display: table-cell;
  vertical-align: middle;
`;

const TooltipIcon = styled(HelpRounded)`
  font-size: 19px !important;
  color: #0f7ed3;
  vertical-align: middle;
  margin-left: 5px;
  margin-bottom: 2px;
`;

export default function Tooltip({ placement = 'rightTop', children, ...rest }: TooltipProps) {
  return (
    <$Tooltip getPopupContainer={(trigger) => trigger} placement={placement} {...rest}>
      {children ? (
        <Container>
          <Content>{children}</Content>
        </Container>
      ) : (
        <TooltipIcon />
      )}
    </$Tooltip>
  );
}

import styled from '@emotion/styled';

import theme from '../../theme';
import $Link from '../../i18n/link';

// NOTE use string instead of styled component here to avoid strange lint issue:
// Expression produces a union type that is too complex to represent. ts(2590)
const LinkBase = `
  color: ${theme.colorsSecondaryBlue};
  line-height: 1.5;
  text-decoration: underline;
  &:hover {
    font-weight: bold;
    text-decoration: underline;
  }
  &:visited {
    color: ${theme.colorsVisitedLinks};
  }
`;

export const Link18 = styled($Link)`
  font-size: 18px;
  ${LinkBase}
`;

export const Link16 = styled($Link)`
  font-size: 16px;
  ${LinkBase}
`;

export const Link14 = styled($Link)`
  font-size: 14px;
  ${LinkBase}
`;

import { IDonorCompany, IRole, IUserProfile } from './user-management';
import { ISelectionStatus, IStageInvitation } from './stage-invite';
import { DocumentVerificationStatusesLists } from './document-verification';
import { IDocument } from './document';
import { IUser } from './auth';

import { ISOString } from '../common';
import { BackgroundCheckStatus, CertificateStatus, FormStatus, IFormPayload, IFormSchema } from '../form';

export interface IProgram {
  id?: string;
  name: string;
  description?: string;
  imageUrl: string | null;
  imageId: string | null;
  programType: ProgramType;
  eventPrivacy?: EventPrivacy;
  eventDetails?: IEventDetails;
  jobCampaignDetails?: IJobCampaignDetails;
  status: ProgramStatus;
  agendaStatus: string | null;
  stages?: IStage[];
  invitation?: IInvitation;
  createdAt?: ISOString;
  updatedAt?: ISOString;
  additionalData?: IJobCampaignDetails;
}

export interface IAgenda {
  programId?: string;
  status: AgendaStatus;
  sessions: IAgendaSession[];
}

export interface IAgendaSession {
  id?: string;
  name: string;
  description?: string;
  startDate: any;
  endDate: any;
  timezone: string;
  participants?: IAgendaUser[];
  location: string;
  documents: IDocument[];
}

export interface IEventAttendee {
  bio?: string | null;
  documentUrl?: string;
  eventRoles?: string[];
  currentUniversityName: string;
  primaryMajor: string;
  userType: string;
  stageType: StageType;
  currentUserStageType?: string;
  user: {
    id: string;
    stageInvitationUserId: string;
    firstName?: string;
    lastName?: string;
    imageId?: string;
    imageUrl?: string | null;
  };
}

export interface IAgendaDownloadPDF {
  status: string;
  location: string;
}

export interface IAgendaUser {
  id: string;
  bio?: string;
  imageId?: string;
  stageId?: string;
  eventRoles?: string[];
  userProfile: Partial<IUserProfile>;
}

export interface IProgramsInfo {
  programs: IProgram[];
  count: number;
}

export interface IInvitation {
  id?: string;
  programId?: string;
  recipients: string[];
  subject: string;
  body: string;
}

export interface IStageInvitationDetail extends IInvitation {
  stageId?: string;
  status?: StageInvitationStatus;
  type?: StageInvitationType;
  startDate?: ISOString;
  endDate?: ISOString;
  timezone?: string;
  inviteNumber?: number;
}

export enum StageInvitationStatus {
  SCHEDULED = 'scheduled',
  DRAFT = 'draft',
  PUBLISHED = 'published',
  CLOSED = 'closed',
}

export enum StageInvitationType {
  Criteria = 'criteria',
  User = 'user',
}

export interface IEventRoom {
  id?: string;
  name: string;
  link: string;
  sponsorId?: string;
  description?: string;
  isVisited?: boolean;
}

export enum ProgramType {
  SCHOLARSHIP = 'scholarship',
  CONFERENCE = 'conference',
  SURVEY = 'survey',
  EVENT = 'event',
  PUBLIC_EVENT = 'publicEvent',
  PRIVATE_EVENT = 'privateEvent',
  JOB_CAMPAIGN = 'jobCampaign',
  VIRTUAL_CAREER_FAIR = 'virtualCareerFair',
}

export enum EventPrivacy {
  PUBLIC_EVENT = 'publicEvent',
  PRIVATE_EVENT = 'privateEvent',
}

export enum AgendaStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}

export enum EventLocation {
  VENUE = 'venue',
  ONLINE = 'online',
  JOB_CAMPAIGN = 'jobCampaign',
  VIRTUAL_CAREER_FAIR = 'virtualCareerFair',
}

export type ProgramCount = Record<ProgramStatus, number>;

export type ProgramFilter = Record<string, any>;

export type ProgramStatus = 'draft' | 'open' | 'closed';

export type StageStatus = 'draft' | 'published' | 'ready' | 'closed' | 'readyInterestForm';

// This should always match the StageType enum in the back-end definition
export enum StageType {
  GENERIC = 'generic',
  NOMINATE_RECOMMENDERS = 'nominateRecommenders',
  SUBMIT_RECOMMENDATION = 'submitRecommendation',
  NOMINATE_COUNSELOR = 'nominateCounselor',
  COUNSELOR = 'counselor',
  SUBMIT_ESSAY = 'submitEssay',
  EVALUATE_ESSAY = 'evaluateEssay',
  DOC_VERIFICATION = 'docVerification',
  CONFIRM_INTERVIEW = 'confirmInterview',
  EVALUATE_INTERVIEW = 'evaluateInterview',
  ACCEPTANCE = 'acceptance',
  SURVEY = 'survey',
  TRAVEL_DETAILS = 'travelDetails',
  READER_APPLICATION = 'readerApplication',
  INTERVIEWER_APPLICATION = 'interviewerApplication',
  COACH_APPLICATION = 'coachApplication',
  MENTEE_APPLICATION = 'menteeApplication',
  MENTOR_APPLICATION = 'mentorApplication',
  CONFERENCE_MENTOR_APPLICATION = 'conferenceMentorApplication',
  READER_CERTIFICATION = 'readerCertification',
  INTERVIEWER_CERTIFICATION = 'interviewerCertification',
  COACH_CERTIFICATION = 'coachCertification',
  MENTOR_CERTIFICATION = 'mentorCertification',
  PUBLIC_EVENT = 'publicEvent',
  PRIVATE_EVENT_APPLICANTS = 'privateEventApplicant',
  PRIVATE_EVENT_NON_APPLICANTS = 'privateEventNonApplicant',
  VIRTUAL_JOB_FAIR = 'virtualJobFair',
  VIRTUAL_CAREER_FAIR = 'virtualCareerFair',
  LINK_TO_APPLY = 'linkToApply',
  INTEREST_FORM = 'interestForm',
  EVENT_BADGE = 'eventPin',
}

export const READER_MANAGER_STAGES = [
  StageType.SUBMIT_ESSAY,
  StageType.EVALUATE_ESSAY,
  StageType.READER_APPLICATION,
  StageType.READER_CERTIFICATION,
];

export const INTERVIEWER_MANAGER_STAGES = [
  StageType.CONFIRM_INTERVIEW,
  StageType.EVALUATE_INTERVIEW,
  StageType.INTERVIEWER_APPLICATION,
  StageType.INTERVIEWER_CERTIFICATION,
];

export enum UserStageFlaggedStatus {
  FLAGGED = 'flagged',
  PENDING = 'pending',
}

export interface IEventDetails {
  startDate?: string;
  endDate?: string;
  timezone: string;
  ianaTimezone: string;
  location?: IEventLocation;
  includeTenantId?: boolean;
  type?: IEventType;
  topic?: IEventTopic;
  badge?: IEventBadge;
  tickets?: ITicketDetail[];
  publishState?: string;
  currency?: string | null;
  eventbriteUrl?: string | null;
  eventbriteId?: string | null;
  organizer?: IEventOrganizer;
}

export interface IEventType {
  eventbriteId: string;
  name: string;
}

export interface IEventTopic {
  eventbriteId: string;
  name: string;
}

export interface IEventOrganizer {
  eventbriteId: string;
  name: string;
}

export interface IEventBadge {
  documentUrl?: string | null;
  documentId: string | undefined;
}

export interface IVenueAddress {
  address1?: string;
  address2?: string;
  zip?: string;
  city?: string;
  region?: string;
  country?: string;
}

export interface IEventLocation {
  venue?: {
    eventbriteId?: string;
    address?: IVenueAddress;
    name?: string;
  };
  online?: {
    url: string;
    imageId: string | null;
    imageUrl?: string | null;
  };
}

export interface INewVenue {
  address: IVenueAddress;
  name?: string;
}

export interface IEventbriteVenue {
  address: IVenueAddress;
  name: string;
  eventbriteId: string;
}

export interface ITicket {
  id: string;
  name: string;
  quantity: string;
  price: string;
  salesStart: string;
  salesEnd: string;
  ticket: ITicketDetail;
}

export interface ITicketDetail {
  id?: string;
  eventbriteId?: string | null;
  name?: string;
  quantityTotal?: number;
  minimumQuantity?: number;
  maximumQuantity?: number;
  cost?: {
    value?: number;
    currency: string;
  } | null;
  free: boolean;
  salesStart?: string;
  salesEnd?: string;
  salesStartAfter?: string | null;
  description?: string | null;
  quantitySold?: number;
}

export enum EventRole {
  SPEAKER = 'Speaker',
  GUEST = 'Guest',
  PANELIST = 'Panelist',
  SPONSOR = 'Sponsor',
  VOLUNTEER = 'Volunteer',
  CAREER_COACH = 'Career coach',
  STAFF = 'Staff',
  MENTOR = 'Mentor',
  PARTICIPANT = 'Participant',
}

export interface IJobCampaignDetails {
  campaignType?: CampaignType;
  level?: CampaignLevel;
  company?: ICompany;
  location?: string;
  locationType?: string;
  owner?: string;
  publishState?: FormStatus;
}

export enum CampaignType {
  INTERNSHIP_OPPORTUNITIES = 'internshipOpportunities',
  JOB_OPPORTUNITIES = 'jobOpportunities',
  WEBINAR = 'webinar',
  OTHER = 'other',
}

export enum CampaignLevel {
  CAMPAIGNS = 'campaigns',
  NEWSLETTERS = 'newsletters',
  QUALIFIED_CANDIDATES = 'qualifiedCandidates',
  RECRUITMENT_SEARCHES = 'recruitmentSearches',
}

export interface ICompany {
  company: string;
  id: string;
}

export interface IStageType {
  key: string; // Key of the enum for BE
  type: string; // select value to show
}

export interface IStage {
  id?: string;
  name: string;
  description?: string;
  urlToApply?: string;
  startDate: string;
  endDate: string;
  timezone: string;
  formSchema?: IFormSchema;
  confirmation?: string;
  status: StageStatus; // draft | published | closed | ready
  createdAt?: ISOString;
  updatedAt?: ISOString;
  programId?: string;
  stageType: StageType;
  toa?: string;
  dependencies?: string[];
  roomsCount?: number;
  rooms?: IEventRoom[];
  expo?: IExpoConfig | null;
  formStatus?: FormStatus;
  isFormDeleted?: boolean;
  userStageId?: string;
  userStageStartDate?: string;
  invitations?: IStageInvitationDetail[];
}

export type ISearchStageUsersRequest = {
  limit: number;
  offset: number;
  flagged?: UserStageFlaggedStatus;
  search?: string;
  formStatus?: string;
  timeInQueue?: string;
  userId?: string;
  columns?: string[];
  [key: string]: any;
  selectionPriority?: string;
} & DocumentVerificationStatusesLists;

export interface IStageUser {
  userId: string;
  programId: string;
  stageId: string;
  formStatus: FormStatus;
  status: CertificateStatus;
  explanation: string;
  backgroundCheck: BackgroundCheckStatus;
  nda?: string;
  hsfId: string;
  flagged: UserStageFlaggedStatus;
  completed?: number; // only for reader/interviewers/students
  pendingFlag?: number; // only for reader/interviewers/students
  userProfile?: IUserProfile;
  email?: string; // only for reader/interviewers/students
  firstName?: string; // only for reader/interviewers/students
  lastName?: string; // only for reader/interviewers/students
  numberOfAssignedReaders?: string; // only for reader/interviewers/students
  phone?: string; // only for reader/interviewers/students
  tenantUserId?: number; // only for reader/interviewers/students
  quizCount?: number; // only for reader/interviewers certification
  quizStatus?: string; // only for reader/interviewers certification
  attendeeStatus?: string; // only for events
  clickedOnLink?: boolean; // only for job campaign users with link to apply stage
  rooms?: IEventRoom[]; // only for virtual job fair stage
  eventRoles?: EventRole[];
  sponsor?: IDonorCompany;
  roles?: IRole[];
  ethnicity?: string;
}

export interface IStageInvitationUser {
  id: string;
  email?: string;
  stageId?: string;
  stage?: StageType;
  stageInvitationId?: string;
  stageInvitation?: IStageInvitation;
  userId?: string;
  user?: IUser;
  attendeeStatus?: string;
  attendeeRole?: string;
  applicantRating?: number;
  agendaSession?: IAgendaSession;
}

export interface IStageAssociatedUser {
  userId: string;
  firstName: string;
  lastName: string;
  tenantUserId: string;
  email: string;
  phone?: string;
  formStatus: FormStatus;
  flagged: UserStageFlaggedStatus;
  flaggedReason: string;
  timeInQueue: string;
  stageId: string;
  requestId: string;
  ethnicity: string | string[];
}

export interface IStageUserInfo {
  users: IStageUser[];
  count: number;
}
export interface IStageAssociatedUserInfo {
  users: IStageAssociatedUser[];
  count: number;
}

export interface IStageUserFormData {
  userId: string;
  name: string;
  programId: string;
  stageId: string;
  formStatus: FormStatus;
  status: CertificateStatus;
  explanation?: string;
  backgroundCheck: BackgroundCheckStatus;
  formPayload: IFormPayload;
  formState: any;
  flagged: UserStageFlaggedStatus;
  createdAt?: ISOString;
  createdBy?: string;
  updatedAt?: ISOString;
  updatedBy?: string;
  userProfile: IUserProfile;
}

export interface IPublishedProgram {
  id: string;
  name: string;
  description?: string;
  imageUrl?: string | null;
  imageId?: string | null;
  programType: ProgramType;
  status: ProgramStatus;
  stages: IPublishedStage[];
  reader?: any; //Optional for Readers
  requester?: any; //Optional for Readers/Interviewers
  completed?: number; //Optional for Readers/Interviewers
  studentTypes?: any[]; // Optional for Reader/Interviewers
}

export interface IPublishedProgramImage {
  imageUrl?: string | null;
  isLoading: boolean;
}

/**
 * This type is used as the response for all stage types
 */
export interface IPublishedStage {
  id: string;
  stageType: StageType;
  name: string;
  description?: string;
  startDate: string;
  endDate: string;
  timezone: string;
  confirmation: string;
  status: StageStatus;
  userStageStartDate?: string;
  isFormDeleted?: boolean; // Optional For Document Verification Stage Type
  formStatus?: FormStatus; // Optional For Document Verification Stage Type
  formPayload?: IFormPayload; // Optional for Reader Request stage response
  formSchema?: IFormSchema; // Optional for Reader Request stage response
}

// export interface IReaderPrograms {
//   programs: IPublishedProgram[];
//   studentTypes: StudentType[];
// }

export interface IReaderProgramDetails {
  id: string;
  name: string;
  description: string;
  status: ProgramStatus;
  programType: ProgramType;
  readerStage: {
    id: string;
    name: string;
    description: string | null;
    startDate: string;
    endDate: string;
    status: StageStatus;
    stageType: StageType;
    formSchema: IFormSchema;
    formPayload?: IFormPayload;
    formState?: any;
    formStatus: FormStatus;
    timeZone: string;
    confirmation: string;
  };
  studentStage: {
    id: string;
    formSchema: IFormSchema;
    formPayload?: IFormPayload;
    formState?: any;
  };
}

export interface IFormStage {
  id: string;
  formSchema: IFormSchema;
  formPayload?: IFormPayload;
  formState?: any;
  firstName?: string;
  lastName?: string;
}

export interface IFormUserStage extends IFormStage {
  userId: string;
  formStatus: FormStatus;
}

export interface IStageAssociateUsersFormDetails {
  id: string;
  name: string;
  description?: string;
  status: ProgramStatus;
  programType: ProgramType;
  readerStages?: IFormUserStage[];
  studentStage: IFormStage;
  interviewerStages?: IFormUserStage[];
  recommenderStages?: IFormUserStage[];
  counselorStages?: IFormUserStage[];
}

export interface IRequestReadResponse {
  requestId: string;
}

export interface ICareerExpoFormData extends ICareerExpoFormStep1, ICareerExpoFormStep2 {
  sponsorConfigs: ISponsor<string>[];
  activityConfigs: IActivity[];
}

export interface ICareerExpoFormStep1 {
  date: string;
  startTime: string;
  endTime: string;
  timezone: string;
  blockDuration: number;
}

export interface ICareerExpoFormStep2 {
  breaks?: IExpoBreak[];
  sponsorsNumber: number;
  activitiesNumber: number;
  locationSponsors?: string;
  locationActivities?: string;
}

export type CareerExpoFormStep2Formatted = Omit<ICareerExpoFormStep2, 'breaks'> & { breaks?: string[] };

export interface IExpoBreak {
  from: string;
  to: string;
}

export interface ISponsor<CSV = IDocument[]> {
  sponsorId: string;
  priority: number | undefined;
  csv: CSV;
  spaces: IExpoScheduleSpace[];
}

export enum activityType {
  LINKED_IN = 'linkedin',
  MOCK_INTERVIEW = 'mockInterview',
  RESUME = 'resume',
}
export interface IActivity {
  type: activityType | undefined;
  spaces: IExpoScheduleSpace[];
}

export interface IExpoConfig {
  id: string;
  date: string;
  startTime: string;
  endTime: string;
  timezone: string;
  blockDuration: number;
  locationActivities?: string;
  locationSponsors?: string;
  stageId: string;
}

export interface IExpo {
  expoId: string;
  details?: IExpoDetails;
  sponsors?: ISponsorSchedule[];
  activities?: IActivitySchedule[];
  activityExceptions?: IActivityException[];
}

export interface IExpoDetails {
  sponsorSchedule: {
    count: number;
  };
  activitySchedule: {
    count: number;
  };
  schedulingException: {
    count: number;
  };
}

export interface ISponsorSchedule {
  id: string;
  expoId: string;
  priority: number;
  sponsor: IDonorCompany;
  sponsorId: string;
  spaces: IExpoScheduleSpace[];
  timeSlots: IExpoTimeSlot[];
}

export interface IActivitySchedule {
  id: string;
  expoId: string;
  type: activityType;
  spaces: IExpoScheduleSpace[];
  timeSlots: IExpoTimeSlot[];
}

export interface IActivityException {
  id: string;
  expoId: string;
  timeSlots: IExpoTimeSlot[];
}

export interface IExpoTimeSlot {
  id: string;
  isBreak: boolean;
  sponsorConfigId?: string;
  activityConfigId?: string;
  participant?: IExpoParticipant;
  status: ScheduleStatuses;
  startTime: string;
  endTime: string;
  userId?: string;
}

export interface IExpoParticipant {
  id: string;
  firstName: string;
  lastName: string;
  tenantUserId: string;
  email: string;
  phone?: string;
}

export enum ScheduleStatuses {
  NOT_COMPLETE = 'notCompleted',
  COMPLETED = 'completed',
}

export enum DownloadableExpoSchedules {
  ALL = 'download-all',
  SPONSOR = 'download-sponsor',
  ACTIVITY = 'download-activity',
  ACTIVITY_EXCEPTION = 'download-activity-exception',
}

export interface IExpoSchedule {
  startTime: string;
  status: ScheduleStatus;
  id: string;
  spaces: IExpoScheduleSpace[];
}

export interface IExpoScheduleSpace {
  name: string;
  location?: string;
}

export interface AddAttendeeFormData {
  firstName?: string;
  lastName?: string;
  tenantUserId?: number;
  phone?: string;
  email?: string;
  eventRoles?: string[];
  sponsor?: string;
  imageId?: string;
  bio?: string;
  shadowUserId?: string;
  stageId?: string;
}

export interface UpdateAttendeeStatusData {
  stageId: string;
  attendeeStatus: string;
  userId?: string;
  shadowUserId?: string;
}

export interface UpdateApplicantRatingData {
  stageId: string;
  applicantRating: number;
  userId: string;
}

export interface DeleteStageFormData {
  stageId: string;
  userId?: string;
  shadowUserId?: string;
  options?: object;
}

export enum StatusOperator {
  IS = 'IS',
  ISNOT = 'IS NOT',
}

export enum ScheduleStatus {
  NOT_COMPLETED = 'notCompleted',
  COMPLETED = 'completed',
}

export type StatusRule = {
  operation: StatusOperator;
  formStatus: string[];
};

export type StageRule = {
  stage?: string;
  status?: StatusRule;
};

export type UserProfileRule = {
  roles?: string[];
  userIds?: string[];
  stages?: StageRule[];
  selectionStatuses?: ISelectionStatus[];
};

export type ResultRule = {
  rule: UserProfileRule;
};

export const EmptyRule: ResultRule = {
  rule: {},
};

export type SaveProgramResult = {
  isValidDate: boolean;
  ticketsName: string;
};

import axios from 'axios';

import { createError } from './error';
import responseParser from './response-parser';

import { API_URL } from '../constants';
import { IPublicTenantConfig, ITenantConfig } from '../types/models/tenant-management';

export async function getTenantConfig(): Promise<ITenantConfig> {
  try {
    const url = `${API_URL}/configs`;
    const response = await axios.get(url);
    const data: ITenantConfig = responseParser(response);

    return data;
  } catch (err) {
    throw createError(err);
  }
}

/**
 * Get tenant configuration values for the public site.
 */
export async function getPublicTenantConfig(): Promise<IPublicTenantConfig> {
  try {
    const url = `${API_URL}/configs/public`;
    const response = await axios.get(url);
    const data: IPublicTenantConfig = responseParser(response);

    return data;
  } catch (err: any) {
    throw createError(err);
  }
}
getPublicTenantConfig.QUERY_KEY = 'getPublicTenantConfig';

import { IDocument } from './document';
import { ICustomAttribute } from './stage-invite';

import { ISOString } from '../common';

export enum BroadcastType {
  EMAIL = 'email',
  TEXT = 'text',
  CALL = 'call',
}
export enum BroadcastStatus {
  DRAFT = 'draft',
  SCHEDULED = 'scheduled',
  PUBLISHED = 'published',
  ON_HOLD = 'onHold',
}

export enum BroadcastMessageStatus {
  PENDING = 'pending', // Awaiting action/queued
  SENT = 'sent', // Request made successfully to SendGrid/Twilio
  FAILED = 'failed', // Request made unsuccessfully to SendGrid/Twilio
  DELIVERED = 'delivered', // Email/SMS delivered
  UNDELIVERABLE = 'undeliverable', // Email/SMS could not be delivered
  MISSING_DATA = 'missing_data', // User is missing data required to send message
}

export interface IBroadcast {
  id: string;
  name: string;
  tags: string[];
  type: BroadcastType;
  scheduleDateTime: ISOString; //or just string?
  status: BroadcastStatus;
}

export type InvitationRule = {
  roles?: string[];
  userIds?: string[];
  emails?: string[];
  phoneNumbers?: string[];
  customAttributes?: ICustomAttribute[];
};

export interface IBroadcastDetails {
  id: string;
  name: string;
  tags?: string[];
  type: BroadcastType;
  subject?: string; // for MESSAGE, EMAIL, NOTIFICATION
  body?: string; // for MESSAGE, EMAIL, NOTIFICATION, TEXT
  emailSender?: string; // for EMAIL
  emailTemplateId?: string; // for EMAIL
  scheduleDateTime?: ISOString;
  document?: IDocument; // for CALL
  status: BroadcastStatus;
  rule?: InvitationRule;
}

export interface IBroadcastDetailsFormData extends Omit<IBroadcastDetails, 'id'> {
  id?: string;
}

export enum TabItem {
  BROADCAST_DETAILS = 'broadcastDetails',
  BROADCAST_ANALYTICS = 'broadcastAnalytics',
}

export interface IBroadcastStatistics {
  id: string;
  pending: number;
  sent: number;
  failed: number;
  delivered: number;
  undeliverable: number;
  missingData: number;
  total: number;
}

export interface IBroadcastMessage {
  id: string;
  status: BroadcastMessageStatus;
  tenantUserId?: number;
  phone?: string;
  email?: string;
  userFullName?: string;
  deliveredAt?: Date;
}

import React, { createContext, useState, useContext, useCallback } from 'react';
import { isEmpty } from 'lodash';
import produce from 'immer';

import { useQuery } from '../../hooks/use-query';
import { MajorClasification, CollegeFinderFilters } from '../../types/models/event-calendar';
import { getEvents } from '../../apis/public/event-calendar';

export type EventCalendarContext = {
  events: MajorClasification[] | null;
  isLoadingEvents: boolean;
  error?: Error | null;
  errorEthnicities?: Error | null;
  filters: CollegeFinderFilters;
  setFilters: (options: CollegeFinderFilters) => void;
  resetFilters: () => void;
  setError?: (error: Error) => void;
};

const initial: EventCalendarContext = {
  events: [],
  isLoadingEvents: true,
  filters: { offset: 0 },
  setFilters: () => undefined,
  resetFilters: () => undefined,
};

const EventCalendarContext = createContext<EventCalendarContext>(initial);

const EventCalendarContextProvider: React.FC<{}> = ({ children }) => {
  const [filters, setFilters] = useState<CollegeFinderFilters>(initial.filters);
  const [error, setError] = useState();

  const { data: events, isLoading: isLoadingEvents } = useQuery(
    [getEvents.QUERY_KEY, filters],
    ({ queryKey }: any) => getEvents(queryKey[1]),
    {
      onError: (error) => {
        setError(error);
      },
    }
  );

  const _resetFilter = useCallback(() => {
    setFilters({ offset: 0 });
  }, []);

  const _setFilters = (options: CollegeFinderFilters) => {
    setFilters((current) => {
      const filters = produce(current, (draft) => {
        if (isEmpty(options)) {
          Object.keys(draft)
            .filter((key) => !['id', 'searchText'].includes(key))
            .forEach((key) => {
              delete draft[key];
            });
        }
      });
      const offset = current.offset != options?.offset ? options?.offset : 0;
      return { ...filters, ...options, offset };
    });
  };

  const _setError = (error: any) => {
    setError(error);
  };

  return (
    <EventCalendarContext.Provider
      value={{
        events,
        isLoadingEvents,
        error,
        setError: _setError,
        filters,
        setFilters: _setFilters,
        resetFilters: _resetFilter,
      }}
    >
      {children}
    </EventCalendarContext.Provider>
  );
};

export default EventCalendarContextProvider;

export const useEventCalendarContext = () => {
  return useContext(EventCalendarContext);
};

import { useSelector } from 'react-redux';

import { RootState } from '../reducers/index';
import { RoleType } from '../../types/models/auth';

export const getAuth = (state: RootState) => state.auth;
export const getToken = (state: RootState) => getAuth(state).token;
export const getUser = (state: RootState) => getAuth(state)?.user;
export const getRoles = (state: RootState) => getUser(state)?.roles || [];

export const hasRole = (role: RoleType) => {
  return (state: RootState) => {
    const roles = getRoles(state) || [];
    return roles.includes(role);
  };
};
export const isAuthenticated = (state: RootState) => {
  const user = getUser(state);
  return !!user;
};
export const isAdmin = (state: RootState) => {
  const roles = getRoles(state) || [];
  return [RoleType.ADMIN, RoleType.DISPATCHER_ADMIN, RoleType.TECHNICIAN_ADMIN, RoleType.MANAGER_ADMIN].some((role) =>
    roles.includes(role)
  );
};

const supporterRoles = [RoleType.SUPPORTER, RoleType.ALC_SELF_IDENTIFIED, RoleType.LEADER_VERIFIED];

export const useRoles = () => {
  const roles: RoleType[] = useSelector(getRoles);

  return {
    currentRole: roles[0],
    canUse: (role: RoleType) => roles.includes(role),
    isTechnicianAdmin: roles.includes(RoleType.TECHNICIAN_ADMIN),
    isManagerAdmin: roles.includes(RoleType.MANAGER_ADMIN),
    isStudent: roles.includes(RoleType.STUDENT),
    isScholar: roles.includes(RoleType.SCHOLAR),
    isInterviewer: roles.includes(RoleType.INTERVIEWER),
    isReader: roles.includes(RoleType.READER),
    isRecommender: roles.includes(RoleType.RECOMMENDER),
    isCounselor: roles.includes(RoleType.COUNSELOR),
    isEducator: roles.includes(RoleType.EDUCATOR),
    isSupporter: roles.some((role) => supporterRoles.includes(role)),
    isParent: roles.includes(RoleType.PARENT),
    isDispatcherAdmin: roles.includes(RoleType.DISPATCHER_ADMIN),
    isSystemAdmin: roles.includes(RoleType.SYSTEM_ADMIN),
    isAlumniSelfIdentified: roles.includes(RoleType.ALUMNI_SELF_IDENTIFIED),
    isHCPIQualifiedUser: roles.includes(RoleType.HCPI_QUALIFIED_USER),
    isAlumniVerified: roles.includes(RoleType.ALUMNI_VERIFIED),
    isFao: roles.includes(RoleType.FAO),
    isDonor: roles.includes(RoleType.DONOR),
    isMentor: roles.includes(RoleType.MENTOR),
    isMentee: roles.includes(RoleType.MENTEE),
    isCoach: roles.includes(RoleType.COACH),
    isAdvisoryCouncilMember: roles.includes(RoleType.ADVISORY_COUNCIL_MEMBER),
    isSponsor: roles.includes(RoleType.SPONSOR),
    isBoardOfDirector: roles.includes(RoleType.BOARD_OF_DIRECTOR),
    isProgramMentor: roles.includes(RoleType.PROGRAM_MENTOR),
    isProgramMentee: roles.includes(RoleType.PROGRAM_MENTEE),
    isAdmin: roles.includes(RoleType.ADMIN),
  };
};

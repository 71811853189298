import React from 'react';

export interface LabelProps {
  children?: React.ReactNode;
  className?: string;
  style?: object;
}

export default function Label({ className, style, children }: LabelProps) {
  return (
    <div className={`ant-label ${className}`} style={style}>
      {children}
    </div>
  );
}

export enum BreakPoints {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = 'xxl',
}

export type BreakPointsType = Record<BreakPoints, number>;

export enum AdminContentLayoutType {
  DEFAULT = 'DEFAULT',
  TABLE = 'TABLE',
}

export enum UserContentLayoutType {
  DEFAULT = 'DEFAULT',
  TABLE = 'TABLE',
  DASHBOARD = 'DASHBOARD',
}

import React, { useEffect } from 'react';
interface GoogleTranslateComponentProps {
  defaultLanguage: string;
  languages: string[];
}

declare const window: any;

let createdGoogleTranslateScript = false;

const GoogleTranslateComponent = ({ languages, defaultLanguage }: GoogleTranslateComponentProps) => {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: `${defaultLanguage}`,
        includedLanguages: `${languages}`,
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        autoDisplay: false,
      },
      'google_translate_element'
    );

    createdGoogleTranslateScript = false;
  };

  useEffect(() => {
    if (!createdGoogleTranslateScript) {
      const addScript = document.createElement('script');
      addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
      document.body.appendChild(addScript);

      window.googleTranslateElementInit = googleTranslateElementInit;

      createdGoogleTranslateScript = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div id="google_translate_element"></div>
      <div className="gx-d-flex justify-content-center"></div>
    </div>
  );
};

export default GoogleTranslateComponent;

import { Modal as $Modal, ButtonProps as $ButtonProps } from 'antd';
import { ModalProps as $ModalProps, ModalFuncProps as $ModalFuncProps } from 'antd/lib/modal';
import React, { useCallback } from 'react';

import { Button, ButtonProps } from '.';

import { TempModalFooterWrapper } from './common/antd/antd-override';

type ButtonPropsOverride = {
  okType?: ButtonProps['type'];
  okButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
};
type OmitPropKeys = 'okType' | 'okButtonProps' | 'cancelButtonProps';

export type ModalProps = Omit<$ModalProps, OmitPropKeys> &
  ButtonPropsOverride & {
    children?: React.ReactNode;
  };
export type ModalFuncProps = Omit<$ModalFuncProps, OmitPropKeys> & ButtonPropsOverride;

export const OK_BUTTON_DATA_TEST_ID = 'ok-button';
export const CANCEL_BUTTON_DATA_TEST_ID = 'cancel-button';

const OK_BUTTON_CLASS_NAME = 'ok-btn';
const CANCEL_BUTTON_CLASS_NAME = 'cancel-btn';

const Modal = function ModalComponent({
  footer,
  okType = 'primary-blue',
  okText = 'OK',
  cancelText = 'Cancel',
  okButtonProps: okButtonPropsProp,
  cancelButtonProps: cancelButtonPropsProp,
  confirmLoading,
  onOk,
  onCancel,
  ...rest
}: ModalProps) {
  const renderFooter = useCallback(() => {
    const okButtonProps = { className: OK_BUTTON_CLASS_NAME, ...okButtonPropsProp };
    const cancelButtonProps = { className: CANCEL_BUTTON_CLASS_NAME, ...cancelButtonPropsProp };
    return (
      <div>
        <Button data-testid={CANCEL_BUTTON_DATA_TEST_ID} onClick={onCancel} {...cancelButtonProps}>
          {cancelText}
        </Button>
        <Button
          data-testid={OK_BUTTON_DATA_TEST_ID}
          type={okType}
          loading={confirmLoading}
          onClick={onOk}
          {...okButtonProps}
        >
          {okText}
        </Button>
      </div>
    );
  }, [cancelButtonPropsProp, cancelText, confirmLoading, okButtonPropsProp, okText, okType, onCancel, onOk]);
  return <$Modal footer={footer === undefined ? renderFooter() : footer} onCancel={onCancel} {...rest} />;
};

function modalMethod(
  type: 'info' | 'confirm' | 'success' | 'warn' | 'error',
  { okButtonProps, cancelButtonProps, ...rest }: ModalFuncProps
) {
  return $Modal[type]({
    // @ts-ignore
    okType: 'primary-blue',
    okButtonProps: {
      className: `${OK_BUTTON_CLASS_NAME} ${type}-${OK_BUTTON_CLASS_NAME}`,
      ...(okButtonProps as $ButtonProps),
    },
    cancelButtonProps: {
      className: `${CANCEL_BUTTON_CLASS_NAME} ${type}-${CANCEL_BUTTON_CLASS_NAME}`,
      ...(cancelButtonProps as $ButtonProps),
    },
    modalRender: (node) => <TempModalFooterWrapper>{node}</TempModalFooterWrapper>,
    ...rest,
  });
}
Modal.info = modalMethod.bind(null, 'info');
Modal.confirm = modalMethod.bind(null, 'confirm');
Modal.success = modalMethod.bind(null, 'success');
Modal.warn = modalMethod.bind(null, 'warn');
Modal.error = modalMethod.bind(null, 'error');
Modal.destroyAll = $Modal.destroyAll;

export default Modal;
